import React, { useState } from "react";
import { API_URL, Password_Pattern} from "./ConstFile";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';

const Changepassword = () => {
  const Auth = localStorage.getItem("user");
  const [errors, setErrors] = useState(false);
  const [errorsSamePassword, seterrorsSamePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [submitting, setSubmitting] = useState(false); // Added state for button disabling
  const [successMessage, setSuccessMessage] = useState(); // State for success message
  const navigate = useNavigate();


  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = async () => {
    if (!newPassword || !confirmPassword) {
      setErrors(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrors(false);
      window.alert("New password and confirm password do not match.");
      return;
    }

       if (!Password_Pattern.test(newPassword)) {
        setErrors(true);
        return;
      }

    setSubmitting(true);

    try {
      const response = await fetch(`${API_URL}change-password`, {
        method: "POST",
        body: JSON.stringify({ email: JSON.parse(Auth).email, newPassword }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setErrors(false);
        seterrorsSamePassword(false);
        setNewPassword("");
        setConfirmPassword("");
        setSuccessMessage("Password has been updated successfully");
        window.location="/main-dashboard";
      } else if(response.status == 400){
        setErrors(false);
        seterrorsSamePassword(true);
        // Handle error cases and provide feedback to the user
      }
    } catch (error) {
      // Handle fetch error and provide feedback to the user
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="mb-3 change-password">
      <label className="mb-2" htmlFor="newPassword">
        <strong className="label">New Password</strong>
      </label>
      <input
        type="password"
        placeholder="Enter your new password"
        name="newPassword"
        value={newPassword}
        onChange={handleNewPasswordChange}
        className="form-control rounded-0"
      />
      <PasswordStrengthBar password={confirmPassword} className="custom-strength-bar"/>

      <label className="mb-2" htmlFor="confirmPassword">
        <strong className="label">Confirm Password</strong>
      </label>
      <input
        type="password"
        placeholder="Confirm your new password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        className="form-control rounded-0"
      />
      <div>
        {successMessage && (
          <span className="text-success">Password has been updated successfully</span>
        )}
      </div>
      <div>
        {/* {errors && <span className="text-danger">The password must consist of minimum 8 characters including special characters and it should not surpass a maximum length of 20 characters</span>} */}
        {errors && 
        <small className="text-danger">
            Password requirements:
          <ul>
            <li>Minimum 8 characters</li>
            <li>Maximum 20 characters</li>
            <li>At least 1 lowercase letter (a-z)</li>
            <li>At least 1 uppercase letter (A-Z)</li>
            <li>At least 1 numeric character (0-9)</li>
            <li>At least 1 special character(~!@#$()-_+={}[]|\;)</li>
          </ul>
        </small>
      }
      {errorsSamePassword && <span className="text-danger">The existing password and new password are the same. Please try with another password</span>}
      </div>
      <button className="mt-3"
        type="button"
        onClick={handleSubmit}
        disabled={submitting} // Disable the button while submitting
      >
        {submitting ? "Submitting..." : "Submit"}
      </button>
    </div>
  );
};

export default Changepassword;
