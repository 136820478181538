import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "./ConstFile";

const Welcome = () => {
  const [searchParams] = useSearchParams(); // Destructure the searchParams
  const code = searchParams.get("code");
  const [responseData, setResponseData] = useState(null);
  const [responseStatus, setResponseStatus] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${API_URL}welcome`, { code });
        if(response.data.message == "Success"){
            setResponseData(response.data);
            setResponseStatus(true);
        } else {
            setResponseData(response.data);
            setResponseStatus(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (code) {
      fetchData();
    }
  }, [code]);

  return (
    
    <>
      {responseStatus ? (
        <div className="container-fluid text-center dd-none py-5" style={ responseStatus ? { 'display':'block'} : {}}>
          <header className="site-header" id="header">
            <h1 className="site-header__title " $ data-lead-id="site-header-title">
              THANK YOU! 
            </h1>
          </header>
  
          <div className="main-content">
            <i className="fa fa-check main-content__checkmark" id="checkmark"></i>
            <p
              className="main-content__body"
              data-lead-id="main-content-body"
            >
              Thanks your email verification is successfully completed! Click on login
            </p>
          </div>
        </div>
      ) : (
          <div className="container-fluid text-center py-5">
          <header className="site-header" id="header">
            <h1 className="site-header__title " $ data-lead-id="site-header-title">
              ERROR! 
            </h1>
          </header>
  
          <div className="main-content">
            <i className="fa fa-close main-content__checkmark2" id="checkmark"></i>
            <p
              className="main-content__body"
              data-lead-id="main-content-body"
            >
              Link is not valid. Please try again or contact admin.
            </p>
          </div>
        </div>
      )}
    </>
  );  
};

export default Welcome;
