import React from "react";

function Refund() {
  return (
    <div className=" container-fluid p-0">
      <div className="hero_sec_pages ">
        <h1 className="hero_heading">Refund and Cancellation Policy</h1>
      </div>

      <div className="container about-page p-2 p-sm-5">
        <p className="page-contant">
          We want your experience with Indralokam to be straightforward and
          satisfactory. This Refund and Cancellation Policy outlines the terms
          and conditions for refunds and cancellations related to our services:
        </p>
        <ol>
          <li className="page-contant my-3">
            <strong>Advertisement Slot Booking :</strong>
            <ul>
              <li className="my-2">
                Advertisements can be booked for a one-month period.
              </li>
              <li className="my-2">
                Once a slot is booked and paid for, it becomes active immediately.
              </li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Refunds :</strong>
            <ul>
              <li className="my-2">
                Refunds are only applicable if a technical error on our part prevents the advertisement from being displayed during the paid period.
              </li>
              <li className="my-2">
                To request a refund, please contact our customer support within seven (7) days of the issue.
              </li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Cancellations :</strong>
            <ul>
              <li className="my-2">
                Users can cancel their advertisement slot at any time.
              </li>
              <li className="my-2">
                Cancellations do not guarantee a refund.
              </li>
              <li className="my-2">
                Any cancellation requests should be made by contacting our customer support.
              </li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Processing Time :</strong>
            <ul>
              <li className="my-2">
                Refunds, if applicable, will be processed within a reasonable timeframe.
              </li>
              </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Contact Us :</strong>
            <ul>
              <li className="my-2">
                If you have questions or need assistance with refunds or cancellations, please contact our customer support using the information provided on our "Contact Us" page.
              </li>
              </ul>
          </li>
        </ol>
        <p className="page-contant">
        Please note that this Refund and Cancellation Policy is subject to change, and the most recent version will be posted on our platform. Users are responsible for reviewing this policy before booking advertisements or requesting refunds or cancellations.
        </p>
      </div>
    </div>
  );
}

export default Refund;