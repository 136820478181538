import { API_URL, keys_id, email } from "../components/ConstFile";
export default async function displayRazorpay(price, listID) {
  price = parseInt(price, 10);
  const data = await fetch(`${API_URL}razorpay`, {
    method: "POST",
    body: JSON.stringify({ price: price, listID: listID }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((t) => t.json());
  // console.log("data");
  // console.log(data);

  let listIDd = data.listID;
  const options = {
    key: keys_id,
    currency: data.currency,
    amount: data.amount,
    slotPrice: data.slotPrice,
    description: "Wallet Transaction",
    image: "./img/nav-logo.jpg",
    order_id: data.id,
    handler: async function (response) {
      
      setTimeout(() => {
        // if (response.razorpay_payment_id) {
          
        window.location.replace(`Success?=`+ listIDd);
        // }
      }, 1500);
      const totalAmount = (data.amount / 100).toFixed(2);
    //   const customerName = "Inderlokam";
    //   const customerEmail = "inderlokam123@gmail.com";
    //   const customerContact = "1234567789";
    //   const customerAddress = "123 Main Street, City, Country";

      const pdf = new window.jspdf.jsPDF();

      pdf.setFillColor(108, 58, 0); // Blue background color
      pdf.rect(
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
        "F"
      );
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(28); // Larger title font size
      pdf.setTextColor(255); // White text color
      pdf.text("Payment Receipt", 15, 30);

      const imgWidth = 40;
      const imgHeight = 40;
      const imgX = pdf.internal.pageSize.getWidth() - imgWidth - 15;
      const imgY = 15;
      pdf.addImage(
        "./img/big-logo.jpg",
        "JPEG",
        imgX,
        imgY,
        imgWidth,
        imgHeight
      );

      const tableData = [
        ["Description", "Value"],
        ["Order ID", response.razorpay_order_id],
        ["Payment ID", response.razorpay_payment_id],
        // ["Customer Name", customerName],
        // ["Customer Email", customerEmail],
        // ["Customer Contact", customerContact],
        // ["Customer Address", customerAddress],
        ["Total Amount", `${totalAmount}`],
      ];

      const startY = 60; // Adjust the starting Y position
      const colWidth = pdf.internal.pageSize.getWidth() / 2.5;
      const rowHeight = 12; // Increased row height
      const cellPadding = 6; // Increased cell padding

      for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        for (let j = 0; j < row.length; j++) {
          const cellX = j * colWidth + 15;
          const cellY = startY + i * rowHeight;
          const textX = cellX + cellPadding;
          const textY = cellY + rowHeight / 2 + cellPadding / 2;

          pdf.setFont(
            i === 0 ? "helvetica-bold" : "helvetica",
            i === 0 ? "bold" : "normal"
          );
          pdf.setFontSize(i === 0 ? 14 : 12);
          pdf.text(row[j], textX, textY);

          if (i > 0) {
            pdf.setDrawColor(200); // Lighter rectangle outline color
            pdf.setLineWidth(0.5); // Thinner border lines
            pdf.rect(cellX, cellY, colWidth, rowHeight, "S");
          }
        }
      }

      pdf.setFont("helvetica");
      pdf.setFontSize(12);
      pdf.setTextColor(255);
      pdf.text(
        `For any inquiries, contact ${email}`,
        105,
        170,
        "center"
      );

      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const downloadLink = document.createElement("a");
      downloadLink.href = pdfUrl;
      downloadLink.setAttribute('target', '_blank');
      downloadLink.download = "order_receipt.pdf";
      downloadLink.textContent = "Download Order Receipt (PDF)";
      downloadLink.style.marginTop = "20px"; // Add some spacing above the link

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      await fetch(`${API_URL}razorpay`, {
        method: "POST",
        body: JSON.stringify({ confirm_order: true, listID: listID }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      // navigate('/main-dashboard');
      // navigate(0);
    },
    prefill: {
      name: "Inderlokam",
      email: "inderlokam123@gmail.com",
      contact: "1234567789",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
