import React, { useEffect, useState } from "react";
import { API_URL, BASE_URL, publish_key } from "./ConstFile";
import { Link, useLocation } from "react-router-dom";

function Success() {
  const urlParams = new URLSearchParams(window.location.search);
  const pid = urlParams.get("pid");
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const Auth = localStorage.getItem("user");

  useEffect(() => {
    // Create a data object to send in the request
    const data = {
      listID: pid,
      paymentSuccess: paymentSuccess,
      email: JSON.parse(Auth).email,
      name: JSON.parse(Auth).name,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    // Define an async function for making the API request
    const sendPaymentSuccess = async () => {
      try {
        const response = await fetch(`${API_URL}success`, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(data),
        });

        if (response.ok) {
          // Handle success response here
          console.log("Payment successful!");
        } else {
          // Handle error response here
          console.error("Payment not successful.");
        }
      } catch (error) {
        // Handle network or other errors
        console.error("An error occurred:", error);
      }
    };

    // Call the function to send the payment success status
    sendPaymentSuccess();
  }, [pid, paymentSuccess]);

  return (
    <div>
      <h1 className="site-header__title text-center my-5">
        Payment Successful!
      </h1>
      <Link to="/main-dashboard">
        <div className="text-center mb-5">
          <p className="tx_dashbord_btn">Go To Dashboard</p>
        </div>
      </Link>
    </div>
  );
}

export default Success;