import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Model from "react-modal";
import EditProductValue from "./EditProductValue";
import { API_URL, BASE_URL, publish_key,is_Razorpay,is_Stripe } from "./ConstFile";
import displayRazorpay from "../utils/PaymentGateway";
import { arrayBufferToBase64 } from "../utils/ImageBinaryConverter";
import ProductDetail from "./ProductDetail";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import PaymentGatewayPage from "./PaymentGatewayPage";

function Maindashbord() {
  const [EditFormOpen, setEditFormOpen] = useState("");
  const [ViewFormOpen, setViewFormOpen] = useState(false);
  const [excludeDates, setExcludeDates] = useState(null);
  const [products, setProducts] = useState([]);
  const [stateExisting, setStateExisting] = useState([]);
  const [cityExisting, setCityExisting] = useState([]);
  const Auth = localStorage.getItem("user");
  const [loading, setLoading] = useState(true);
  const [selectedState, setSelectedState] = useState(""); // State filter
  const [selectedCity, setSelectedCity] = useState(""); // City filter
  const [filteredCity, setfilteredCity] = useState([]); // City filter
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const dropdownRef = useRef(null);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false); // State for payment modal
  const [paymentData, setPaymentData] = useState(null); // Payment data
  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAdminDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.body.addEventListener("click", closeDropdown);
    return () => {
      document.body.removeEventListener("click", closeDropdown);
    };
  }, []);
  function truncateDescription(description, maxWords) {
    const words = description.split(" ");
    if (words.length <= maxWords) {
      return description;
    }
    const truncatedDescription = words.slice(0, maxWords).join(" ");
    return `${truncatedDescription}...`;
  }
  const handleChangePassword = () =>{
    navigate("/change-password");
  }

  const handleEditForm = (product) => {
    setEditFormOpen(product);
  };
  const handleViewForm = (productId) => {
    setViewFormOpen(productId);
  };
  const handleAdminDropdownToggle = () => {
    setAdminDropdownOpen(!adminDropdownOpen);
  };

  const handlePaymentMethod = async (product) => {
    
    setPaymentData(product);
    setPaymentModalIsOpen(true);
    if (product.slot) {
      let query = `slotTitle=${product.slot}&selectedCity=${product.selectedCity}&selectedParentBoxVal=${product.selectedParentBoxVal}`;
      let getSlotDates = await fetch(`${API_URL}users?${query}`, {
        method: "GET",
      });
      getSlotDates = await getSlotDates.json();
      if (getSlotDates.length > 0) {
        getSlotDates.map((item, index) => {
          if (item.selectedStartDate && item.paymentStatus == true) {
            alert("This Slot is Booked");
          }
        });
      } else {
        let payPrice;
        if (product.selectedParentBoxVal === "Property") {
          if (product.selectedChildBoxVal === "Sale") {
            payPrice = 0.05;
          } else {
            payPrice = 1;
          }
        } else {
          payPrice = 1;
        }
      }
    }
  };

  const StripeDisplay = async (product) => {
    console.log(product);
    if (product.slot) {
      let query = `slotTitle=${product.slot}&selectedCity=${product.selectedCity}&selectedParentBoxVal=${product.selectedParentBoxVal}`;
      let getSlotDates = await fetch(`${API_URL}users?${query}`, {
        method: "GET",
      });
      getSlotDates = await getSlotDates.json();
      if (getSlotDates.length > 0) {
        getSlotDates.map((item, index) => {
          if (item.selectedStartDate && item.paymentStatus == true) {
            alert("This Slot is Booked");
          }
        });
      } else {
        let payPrice;
        if (product.selectedParentBoxVal === "Property") {
          if (product.selectedChildBoxVal === "Sale") {
            payPrice = 0.05;
          } else {
            payPrice = 1;
          }
        } else {
          payPrice = 1;
        }
        const stripe = await loadStripe(publish_key);
        const response = await fetch(`${API_URL}create-checkout-session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: product.title,
            price: (product.price * payPrice) / 100,
            productID: product._id,
          }),
        });
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          console.log(result.error);
        }
      }
    }
  };

  const razorPayDisplay = async (product) => {
    console.log(product);
    if (product.slot) {
      let query = `slotTitle=${product.slot}&selectedCity=${product.selectedCity}&selectedParentBoxVal=${product.selectedParentBoxVal}`;
      let getSlotDates = await fetch(`${API_URL}users?${query}`, {
        method: "GET",
      });
      getSlotDates = await getSlotDates.json();
      if (getSlotDates.length > 0) {
        getSlotDates.map((item, index) => {
          if (item.selectedStartDate && item.paymentStatus == true) {
            alert("This Slot is Booked");
          }
        });
      } else {
        let payPrice;
        if (product.selectedParentBoxVal === "Property") {
          if (product.selectedChildBoxVal === "Sale") {
            payPrice = 0.05;
          } else {
            payPrice = 1;
          }
        } else {
          payPrice = 1;
        }
        displayRazorpay((product.price * payPrice) / 100, product._id);
        // console.log(product._id);
      }
    }
  };

  const getListings = async () => {
    setLoading(true);
    var query = `?user_id=${JSON.parse(Auth)._id}`;
    try {
      let listings = await fetch(`${API_URL}users/${query}`, {
        method: "GET",
      });
      listings = await listings.json();
      if (listings) {
        let cityExist = [];
        let stateExist = [];
        listings.map((item, index) => {
          if (item.selectedState) {
            var existingState = item.selectedState;
            var existingCity = item.selectedCity;

            if (!cityExist.includes(existingCity)) {
              cityExist.push(existingCity);
            }
            if (!stateExist.includes(existingState)) {
              stateExist.push(existingState);
            }
          }
        });
        setStateExisting(stateExist);
        setCityExisting(cityExist);
      }
      if (listings) {
        setProducts(listings);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  // const handlePaymentMethod = (product) => {
  //   // Open payment modal
  // };
  const handlePaymentValue = (product) => {
    setEditFormOpen(product);
  };
  const handleStateChange = async (e) => {
    setLoading(true);
    setSelectedState(e.target.value);
    var query = `?user_id=${JSON.parse(Auth)._id}`;
    let listings = await fetch(`${API_URL}users/${query}`, {
      method: "GET",
    });
    listings = await listings.json();
    if (listings) {
      let filterCity = [];
      listings.map((item, index) => {
        if (item.selectedState == e.target.value) {
          var existingCity = item.selectedCity;

          if (!filterCity.includes(existingCity)) {
            filterCity.push(existingCity);
          }
        }
      });
      setfilteredCity(filterCity);
      setSelectedCity("");
    }
    setLoading(false);
  };
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const deleteAccount = async () => {
    const userConfirmed = window.confirm(
      "Warning: This operation cannot be undone, Your profile details & all listing data will be deleted permanently! Are you sure want to delete your account?"
    );
    if (userConfirmed) {
      try {
        const Auth = localStorage.getItem("user");
        const userEmail = JSON.parse(Auth).email;
        var values = { email: userEmail };
        const response = await axios.post(`${API_URL}delete-account`, values);
        const id = JSON.parse(Auth)._id;
        const url = `${API_URL}deletemongo/${id}`;
        const dlt_mongo_data = await fetch(url, {
          method: "GET",
        });
        if (response.status === 200 || dlt_mongo_data.status === 200) {
          localStorage.removeItem("user");
          window.location="/home";
        } else {
          console.log("Account deletion failed.");
        }
       } catch (error) {
        console.error("Error deleting account:", error);
      }
    }
  };
  
  const handledeleteproduct = async (product_id) => {
    const userConfirmed = window.confirm("Are you sure you would like to delete the listing");

    if (userConfirmed) {
      let listings = await fetch(`${API_URL}deleteproduct/${product_id}`, {
        method: "GET",
      });

      alert("Deleted Successfully");
      navigate(0);
    }
  };

  const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());

    date.setDate(date.getDate());

    const dates = [];

    while (date < endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    //console.log(dates);
    return dates;
  };
  const getFormattedDate = (selectedDate) => {
    if (selectedDate) {
      const start = new Date(selectedDate);
      const day = String(start.getDate()).padStart(2, "0");
      const month = String(start.getMonth() + 1).padStart(2, "0");
      const year = String(start.getFullYear()).slice(-2);
      const formattedStartDate = `${day}/${month}/${year}`;
      return formattedStartDate;
    }
    return selectedDate;
  };

  useEffect(() => {
    getListings();
  }, []);

  return (
    <>
      <div className="container-fluid dash-banner">
        <div className="container">
          <div className="d-flex justify-content-between items-center">
            <div>
              <p className="Listing_title">Your Listing</p>
            </div>
            <div className="profile dropdown" ref={dropdownRef}>
              <div
                className="admin-profile d-flex align-items-center"
                onClick={handleAdminDropdownToggle}
              >
                <p className="admin-name dropdown-toggle">
                  <span>{Auth ? JSON.parse(Auth).name : ""}</span>
                </p>
                <img className="profile-img" src="./img/omega.jpg" alt="" />
              </div>
              {adminDropdownOpen && (
                <ul
                  class="dropdown-menu show show-"
                  data-popper-placement="bottom-start"
                >
                  <li>
                    <a class="dropdown-item" onClick={handleChangePassword} >
                      Change Password
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={() => deleteAccount(true)}
                    >
                      Delete Account
                    </a>
                  </li>
                  {/* <li><a class="dropdown-item" href="#" onClick={() => logout(true)}>Logout</a></li> */}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-wrap txx_dropdown p-2 p-md-4 mt-5">
          <div className="col-12 col-md-3 col-lg-2 me-md-4">
            <select
              name="state"
              id="state"
              class="w-100"
              value={selectedState}
              onChange={handleStateChange}
            >
              <option value="select-state">Select State</option>
              {stateExisting.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-3 col-lg-2 me-md-4 mt-4 mt-md-0">
            <select
              name="city"
              id="city"
              class="w-100"
              value={selectedCity}
              onChange={handleCityChange}
            >
              <option value="select-city">Select City</option>
              {filteredCity.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex flex-wrap py-2">
          {loading ? (
            <div class="tx_spinner">
              <div class="spinner-border " role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          ) : products.length === 0 ? (
            <div className="no-listings-message">
              <p>You don't have any listings yet. Start listing now!</p>
            </div>
          ) : (
            products
              .filter((product) => {
                if (
                  (selectedState === "select-state" || !selectedState) && // Check if state is "select-state" or not selected
                  (selectedCity === "select-city" ||
                    selectedCity === product.selectedCity) // Check if city matches the selected city
                ) {
                  return true;
                } else if (selectedState && selectedCity) {
                  return (
                    product.selectedState === selectedState &&
                    product.selectedCity === selectedCity
                  );
                } else if (selectedState) {
                  return product.selectedState === selectedState;
                } else if (selectedCity) {
                  return product.selectedCity === selectedCity;
                }
                return true;
              })

              .map((product, index) => {
                let base64String = "";
                if (
                  product.selectedImageUrl &&
                  product.selectedImageUrl[0] &&
                  product.selectedImageUrl[0].data
                ) {
                  base64String = arrayBufferToBase64(
                    product.selectedImageUrl[0].data.data
                  );
                }
                return (
                  <div
                    className="product-box dash-product cursor-pointer"
                    key={index}
                  >
                    <span className="dlt_icon">
                      <i
                        class="fa-regular fa-trash-can"
                        onClick={() => handledeleteproduct(product._id)}
                      ></i>
                    </span>
                    <img
                      src={
                        product.selectedImageUrl[0] &&
                        product.selectedImageUrl[0].data
                          ? `data:image/png;base64,${base64String}`
                          : process.env.PUBLIC_URL + "/img/product-1.png"
                      }
                      alt={
                        product.selectedImageUrl &&
                        product.selectedImageUrl[0] &&
                        product.selectedImageUrl[0].data
                          ? "Selected Image Alt Text"
                          : "Default Image Alt Text"
                      }
                      className="product-image"
                    />
                    <div className="product-title pb-1">
                      {product.title.length > 15
                        ? product.title.substring(0, 25) + "..."
                        : product.title}
                    </div>
                    <div className="product-description pb-1">
                      {truncateDescription(product.description, 7)}
                    </div>
                    <div className="product-price product-box-price pb-1">
                      ₹ {product.price}
                    </div>
                    <div className="product-price">{product.slot}</div>
                    <div className="product-price">{product.selectedCity}</div>
                    <div className="d-flex d-lg-block d-xl-flex justify-content-between mb-3">
                      <div className="product-price">
                        {getFormattedDate(product.selectedStartDate)}
                      </div>
                      <div className="product-price">
                        {getFormattedDate(product.selectedEndDate)}
                      </div>
                    </div>
                    {!product.paymentStatus && (
                      <div className="make-payment">
                        <button onClick={() => handlePaymentMethod(product)}>
                          Make Payment
                        </button>
                      </div>
                    )}
                    {product.paymentStatus && product.editCompleted && (
                      <div className="edit ">
                        <div className="d-flex">
                          <button onClick={() => handleEditForm(product)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    )}
                    {product.paymentStatus && (
                      <div className="view_div">
                        <button
                          className="product_view_btn"
                          onClick={() => handleViewForm(product._id)}
                        >
                          View
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
          )}
          <Model isOpen={EditFormOpen}>
            <div className="d-flex justify-content-between">
              <h1 className="form-heading">Edit Your Details</h1>
              <div
                className="close-modal"
                onClick={() => setEditFormOpen(false)}
              >
                <i className="fa-solid fa-xmark "></i>
              </div>
            </div>
            {EditFormOpen && <EditProductValue products={EditFormOpen} />}
          </Model>
          <Model isOpen={ViewFormOpen}>
            <div className="d-flex justify-content-between">
              <div
                className="close-modal"
                onClick={() => setViewFormOpen(false)}
              >
                <i className="fa-solid fa-xmark "></i>
              </div>
            </div>
            {ViewFormOpen && <ProductDetail productId={ViewFormOpen} />}
          </Model>
        </div>
      </div>

      <Model
        isOpen={paymentModalIsOpen}
        onRequestClose={() => setPaymentModalIsOpen(false)}
      >
        {/* {paymentData && <PaymentGatewayPage paymentData={paymentData} />}  */}
        
        <div className="container">
          <h2 className="payment-text mb-3">Make Payment</h2>
          <div className="flex justify-content-center">
            {is_Stripe ? (
              <div className="logo_1 mx-2 stripe_box" onClick={() => StripeDisplay(paymentData)}>
                <img src="./img/Stripe.png" alt="Stripelogo" />
              </div>
            ) : null}

              {is_Razorpay ? (
              <div className="logo_2" onClick={() => razorPayDisplay(paymentData)}>
                <img src="./img/Razorpay_payments.png" alt="Razorpaylogo" />
              </div>
            ) : null}
          </div>
        </div>
      </Model>
    </>
  );
}

export default Maindashbord;
