import React from "react";

function Privacypolicy() {
  return (
    <div className=" container-fluid p-0">
      <div className="hero_sec_pages ">
        <h1 className="hero_heading">Privacy Policy</h1>
      </div>

      <div className="container about-page p-2 p-sm-5">
        <p className="page-contant">
          Welcome to Indralokam. Your privacy is important to us. This Privacy
          Policy explains how we collect, use, and protect your personal
          information when you use our services. By accessing or using our
          platform, you consent to the practices described in this policy.
        </p>
        <ol>
          <li className="page-contant my-3">
            <strong>Information We Collect:</strong>
            <ul>
              <li className="my-2">
                <strong>Personal Information:</strong> When you register for an account or use our services, we may collect personal information such as your name, email address, contact number, and address.
              </li>
              <li className="my-2">
              <strong>Payment Information:</strong>If you make payments on our platform, we may collect payment-related information, including billing details.
              </li>
              <li className="my-2">
              <strong>Usage Data:</strong>We may collect information about how you use our platform, including your interactions with advertisements and other content.
              </li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>How We Use Your Information</strong>
            <ul>
              <li className="my-2">
                <strong>To Provide Services: </strong> We use your information to provide, maintain, and improve our services, including displaying advertisements and processing payments.
              </li>
              <li className="my-2">
              <strong>Communication:</strong>We may use your contact information to communicate with you about your account, services, and promotional offers.
              </li>
              <li className="my-2">
              <strong>Analytics:</strong>We use data analytics to better understand user behavior and improve our platform.
              </li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Data Sharing</strong>
            <ul>
              <li className="my-2">
                We do not sell or rent your personal information to third parties.
              </li>
              <li className="my-2">
                Your information may be shared with trusted service providers who assist us in delivering our services.
              </li>
              <li className="my-2">
                We may share aggregated and anonymized data for research and analytics purposes.
              </li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Data Security</strong>
            <ul>
              <li className="my-2">
                We take reasonable measures to protect your information, but no method of transmission over the internet is 100% secure.
              </li>
              <li className="my-2">
                You are responsible for keeping your account credentials confidential.
              </li>              
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Your Choices</strong>
            <ul>
              <li className="my-2">
                You can update or delete your account information by accessing your account settings.
              </li>
              <li className="my-2">
                You can opt out of promotional emails by following the instructions in the email.
              </li>              
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Changes to this Policy</strong>
            <ul>
              <li className="my-2">
                We may update this Privacy Policy as our services evolve. We will notify users of any significant changes.
              </li>                        
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Contact Us</strong>
            <ul>
              <li className="my-2">
                If you have questions or concerns about our Privacy Policy, please contact us using the information provided on our "Contact Us" page.
              </li>                        
            </ul>
          </li>
          
          </ol>
      </div>
    </div>
  );
}

export default Privacypolicy;
