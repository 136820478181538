import React, { useState } from "react";
import { API_URL } from "./ConstFile";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const forgotPasswordCheck = async () => {
    setLoading(true);

    if (!email) {
    setErrorMessage("Please input your email and try again.");
    setLoading(false);
    return;
  }

    try {
      const response = await fetch(`${API_URL}forgot-password`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setSuccessMessage("Forgot password email has been sent successfully.");
      } else {
        setErrorMessage(
          "Email does not exist. Please try with valid email."
        );
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  return (
    <div className="mb-3 forget-password">
      <label htmlFor="email" className="mb-2">
        <strong className="label">Email</strong>
      </label>
      <input
        type="email"
        placeholder="Enter Email"
        name="email"
        id="email"
        onChange={handleEmailChange}
        className="form-control rounded-0"
        required
      />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <button type="button" onClick={forgotPasswordCheck}>
          Send Reset Email
        </button>
      )}

      {successMessage && <p className="text-success">{successMessage}</p>}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
};

export default Forgotpassword;