import React, { useState, useEffect } from "react";
import { State, City } from "country-state-city";
import Select from "react-select";
import {updateCity} from "./ConstFile";


// Define a mapping of old city names to new city names
const cityMapping = updateCity;

const CityDropdown = (props) => {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [indianStates, setIndianStates] = useState([]);
  const [stateCities, setStateCities] = useState([]);

  useEffect(() => {
    // Get Indian states
    const indiaStates = State.getStatesOfCountry("IN");
    setIndianStates(indiaStates);
  }, []);

  const handleCityChange = (item) => {
    setSelectedCity(item);
    props.onCitySelect(item.name);
  };

  const handleStateChange = (item) => {
    setSelectedState(item);
    props.onStateSelect(item.name);

    // Get cities for the selected state
    const cities = City.getCitiesOfState("IN", item.isoCode);
    console.log(cities);

    // Check and update city names based on the mapping
    const updatedCities = cities.map((city) => ({
      ...city,
      name: cityMapping[city.name] || city.name,
    }));

    setStateCities(updatedCities);
  };

  return (
    <div className="App">
      <Select
        options={indianStates}
        getOptionLabel={(options) => {
          return options["name"];
        }}
        getOptionValue={(options) => {
          return options["isoCode"];
        }}
        value={selectedState}
        onChange={handleStateChange}
        placeholder="Select State"
      />
      <Select
        options={stateCities}
        getOptionLabel={(options) => {
          return options["name"];
        }}
        getOptionValue={(options) => {
          return options["name"];
        }}
        value={selectedCity}
        onChange={handleCityChange}
        placeholder="Select City"
      />
    </div>
  );
};

export default CityDropdown;
