import React, { useState } from 'react';
import { BlurhashCanvas } from "react-blurhash";

function ImageLoader(props) {

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  }

  return (
    <div className="">
      {imageLoaded ? (
        <img
          className="nav-image-width"
          src={props.src}
          alt={props.alt}          
          width={props.height}
          height={props.height}
        />
      ) : (
        
        <BlurhashCanvas
          hash="LRKdPDj@SzoextoeWBoL~poe%1j["
          width={props.height}
          height={props.height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        >
          <img
            className="image-width"
            src="./img/big-logo.jpg"
            alt="Biglogo"
            onLoad={handleImageLoad}
            onError={() => setImageLoaded(true)} // Set imageLoaded to true on error to show Blurhash
          />
        </BlurhashCanvas>
      )}
    </div>
  );
}

export default ImageLoader;
