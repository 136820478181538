import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { API_URL, publish_key,is_Razorpay,is_Stripe } from "./ConstFile";
import displayRazorpay from "../utils/PaymentGateway";
function PaymentGatewayPage(props) {
 
  const [payValuePrice, setPayValuePrice] = useState("");
  const { paymentData } = props;
  const { title, price, productID } = paymentData;

  const makePayment = async () => {
    const stripe = await loadStripe(`${publish_key}`);
    const body = {
      name: title,
      price: price,
      productID: productID,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch(`${API_URL}create-checkout-session`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    });
    const session = await response.json();
    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });
    if (result.error) {
      console.log(result.error);
    }
  };

  const handlePaymentMethod = async () => {
      displayRazorpay(price, paymentData.productID);
  };

  return (
     <div className="container">
     <h2 className="payment-text mb-3">Make Payment</h2>
     <div className="flex justify-content-center">
       {is_Stripe ? (
         <div className="logo_1 mx-2 stripe_box"onClick={makePayment}>
           <img src="./img/Stripe.png" alt="Stripelogo" />
         </div>
       ) : null}
         {is_Razorpay ? (
         <div className="logo_2" onClick={handlePaymentMethod}>
           <img src="./img/Razorpay_payments.png" alt="Razorpaylogo" />
         </div>
       ) : null}
     </div>
   </div>
  );
}

export default PaymentGatewayPage;