import React, { useState } from 'react';
import {propertylabel, boxData, additionalBoxes} from './ConstFile';

const BoxUIWithSelection = (props) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [showAdditionalBoxes, setShowAdditionalBoxes] = useState(false);
  const [selectedAdditionalBox, setSelectedAdditionalBox] = useState('');
  
  const handleValueSelect = (value) => {
    setSelectedValue(value);
    if (value === propertylabel) {
      setShowAdditionalBoxes(!showAdditionalBoxes);

    } else if (value === 'Furniture') { 
      props.boxValues({ selectedValue: value });      
      setShowAdditionalBoxes(false);
      setSelectedAdditionalBox('');
    }
  };

  const handleAdditionalBoxSelect = (boxLabel) => {
    setSelectedAdditionalBox(boxLabel);
    props.boxValues({selectedValue,boxLabel});
  };

  return (
    <div>
      <div className="box-container d-flex justify-content-between">
        {boxData.map((box) => (
          <div
            key={box.id}
            className={`box ${selectedValue === box.label ? 'selected' : ''}`}
            onClick={() => handleValueSelect(box.label)}
          >
            <p>{box.label}</p>
          </div>
        ))}
      </div>
      {selectedValue && <p className='select_item mt-2'>Selected value: {selectedValue}</p>}

      {selectedValue === propertylabel && showAdditionalBoxes && (
        <div className="additional-box-container d-flex justify-content-between mt-2">
          {additionalBoxes.map((box) => (
            <div
              key={box.id}
              className={`box additional-box ${selectedAdditionalBox === box.label ? 'selected' : ''}`}
              onClick={() => handleAdditionalBoxSelect(box.label)}
            >
              <p>{box.label}</p>
            </div>
          ))}
          </div>
      )}
    </div>
  );
};

export default BoxUIWithSelection;