import React from "react";

function Termsofuse() {
  return (
    <div className=" container-fluid p-0">
      <div className="hero_sec_pages ">
        <h1 className="hero_heading">Terms & Conditions</h1>
      </div>

      <div className="container about-page p-2 p-sm-5">
        <p className="page-contant">
          Welcome to Indralokam, an online platform dedicated to helping users
          advertise properties for sale/rent and furniture shops. By accessing
          or using our services, you agree to comply with and be bound by the
          following terms and conditions:
        </p>
        <ol>
          <li className="page-contant my-3">
            <strong>Acceptance of Terms</strong> By using our platform, you
            acknowledge that you have read, understood, and accepted these terms
            and conditions. If you do not agree with any part of these terms,
            please refrain from using our services.
          </li>
          <li className="page-contant my-3">
            <strong>User Responsibilities :</strong>
            <ul>
                <li className="my-2">You are responsible for the accuracy and legality of the content you post on our platform.</li>
                <li className="my-2">You agree not to post any fraudulent, misleading, or harmful information.</li>
                <li className="my-2">You must be at least 18 years old to use our services.</li>
            </ul>
          </li>
          <li className="page-contant my-3">
            <strong>Advertisements :</strong>
            <ul>
                <li className="my-2">Users can place advertisements for properties for sale/rent and furniture shops.</li>
                <li className="my-2">Advertisements can be booked for a one-month period.</li>
                <li className="my-2">Users must pay for their chosen advertisement slot before it becomes active.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Payment :</strong>
            <ul>
                <li className="my-2">Payment for advertisement slots can be made through our designated payment gateway.</li>
                <li className="my-2">All payments are subject to our refund and cancellation policy, which can be found on our "Refund and Cancellation Policy" page.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Content Moderation :</strong>
            <ul>
                <li className="my-2">We reserve the right to review and moderate all content posted on our platform.</li>
                <li className="my-2">Inappropriate or violating content may be removed, and offending users may be banned.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Intellectual Property :</strong>
            <ul>
                <li className="my-2">All content provided by users remains their intellectual property.</li>
                <li className="my-2">By posting content on our platform, you grant us a non-exclusive, royalty-free, worldwide license to use, display, and distribute the content for promotional purposes.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Privacy :</strong>
            <ul>
                <li className="my-2">We respect your privacy and handle your personal data in accordance with our Privacy Policy, which can be found on our "Privacy Policy" page.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Disclaimer :</strong>
            <ul>
                <li className="my-2">We do not endorse or guarantee the accuracy of any advertisements or content posted on our platform.</li>
                <li className="my-2">Users are advised to verify information independently before making any decisions based on the content provided.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Limitation of Liability :</strong>
            <ul>
                <li className="my-2">We are not liable for any direct or indirect damages, losses, or inconveniences resulting from the use of our services.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Changes to Terms :</strong>
            <ul>
                <li className="my-2">We reserve the right to modify these terms and conditions at any time. Users will be notified of changes, and continued use of our platform constitutes acceptance of the updated terms.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Termination :</strong>
            <ul>
                <li className="my-2">We reserve the right to terminate or suspend user accounts for violations of these terms.</li>
            </ul>
          </li>

          <li className="page-contant my-3">
            <strong>Contact Us :</strong>
            <ul>
                <li className="my-2">If you have any questions or concerns about these terms and conditions, please contact us via the information provided on our "Contact Us" page.</li>
            </ul>
          </li>
        </ol>
        <p className="page-contant">
        By using Indralokam, you agree to abide by these terms and conditions. Please read them carefully and make sure you understand your obligations as a user of our platform.
        </p>
      </div>
    </div>
  );
}

export default Termsofuse;
