import React, { useState } from "react";
import { API_URL } from "./ConstFile";

const Invite = () => {
  const [errors, setErrors] = useState(false);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const Auth = localStorage.getItem("user");

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const sendEmail = () => {
    if (!email || !validateEmail(email)) {
      setErrors(true);
      return;
    }

    fetch(`${API_URL}invite`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
        name: Auth ? JSON.parse(Auth).name : "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          setSuccessMessage("Invitation email has been sent successfully");
          window.location.reload();
        } else {
          console.error("Failed to send invitation email");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <div className="mb-3 invite-component">
      <label className="mb-2" htmlFor="email">
        <strong className="label">Please enter the email address....</strong>
      </label>
      <input
        type="email"
        placeholder="Enter Email"
        name="email"
        onChange={(e) => {
          setEmail(e.target.value);
          setErrors(false);
        }}
        className="form-control rounded-0"
      />
      <div>
        {successMessage && (
          <span className="text-success">{successMessage}</span>
        )}
        {errors && !validateEmail(email) && (
          <span className="text-danger">
            Please enter a valid email address.
          </span>
        )}
      </div>
      <div>
        <button type="button" onClick={sendEmail}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Invite;
