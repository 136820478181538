const API_URL = "https://www.indralokam.com/mdb";
const BASE_URL = "https://www.indralokam.com:3031/";
const HomeURL = "https://www.indralokam.com";
const Local_Url = "http://localhost:3000/";

// Mongodb connection.
const mongoURI = "mongodb+srv://bonafidebackend:testing1234@cluster0.clx4gww.mongodb.net/Inder_Lokem?retryWrites=true&w=majority";

//SMTP Mail Configuration
const Mail_host = "smtp.gmail.com";
const Mail_port = "465";
const SMTP_USER = "indralokam2023@gmail.com"; // SMTP email user
const SMTP_PASS = "tygb imds eerr cuvq"; // SMTP email password

//Razorpay Key ID & Secret.
const keys_id = "rzp_live_fionumvQCkZsuC";
const keys_secret = "J1VDFnX9V3PKv9gKhsDbZXaV";

//stripe
const secret_key = "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";
const publish_key = "pk_live_51O3599SDaI3npW0KBf2DwcH6IJIXqihaIzSrNzFTxQGq57F94rZcCSNCh6ogaG7ACvC6B2lOAAhSgWd3s72u82O400Z09E1WyD";

//payment getway
const is_Stripe = true;
const is_Razorpay = true;

//Email_Pattern
const Email_Pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

//Password_Pattern
const Password_Pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

//Properties & Furniture
const propertylabel = "Property";
const furniturelabel = "Furniture";
const salelabel = "Sale";
const rentallabel = "Rental";

const boxData = [
  { id: 1, label: propertylabel },
  { id: 2, label: furniturelabel },
];

const additionalBoxes = [
  { id: 3, label: salelabel },
  { id: 4, label: rentallabel },
];

const Turl = 'https://merchant.razorpay.com/policy/MX4baEISgOx0p1/terms';
const Terms = `Please agree to our <a href="${Turl}" target="_blank">terms and conditions</a> to proceed`;

const Restrictions = "Confirm your age is 18 or above";
const email = "Indralokam2023@gmail.com";

const mongoose = require('mongoose');
const imageSchema = new mongoose.Schema({
  src: String,
  alt: String,
  blurhash: String,
  width: Number,
  height: Number,
});

//Platfrom Fee
const salePlatformFee = 0.05;
const rentalPlatformFee = 1;
const furniturePlatformFee = 1;

//updated city names
const updateCity = {

    //Assam
    "Sibsagar": "Sivasagar",
  
    //Haryana
    "Mustafabad": "Saraswati",
    "Gurgaon": "Gurugram",
  
    //Goa
    "Sanquelim": "Sankhali",
    "Curchorem": "Kudchade",
  
    //karnatka
    "Bangalore Rural": "Bengaluru Rural",
    "Bangalore Urban": "Bengaluru Urban",
    "Shimoga": "Shivamogga",
    "Hospet": "Hosapete",
  
    //kerala
    "Cochin": "Kochi",
    "Badagara": "Vatakara",
    "Tellicherry": "Thalassery",
    "Palghat": "Palakkad",
  
    //Madhya Pradesh
    "Hoshangabad": "Narmadapuram",
  
    //Maharashtra
    "Aurangabad": "Sambhaji Nagar",
    "Chanda": "Chandrapur",
    "Daulatabad": "Devagiri",
    "Osmanabad": "Dharashiv",
    "Kalamb, Osmanabad": "Kalamb, Dharashiv",
  
  //Mizoram
  "Saiha": "Saiha",
  
  //Punjab
  "Mohali": "Sahibzada Ajit Singh Nagar, Mohali",
  
  //Tamil Nadu
  "Ooty": "Udagamandalam",
  "Dharmapuri": "Tharumapuri",
  "Arcot": "Aarkadu",   
  
};

module.exports = mongoose.model('Image', imageSchema);
module.exports = {
  API_URL,
  BASE_URL,
  Mail_host,
  Mail_port,
  SMTP_USER,
  SMTP_PASS,
  keys_id,
  keys_secret,
  boxData,
  additionalBoxes,
  propertylabel,
  furniturelabel,
  salelabel,
  rentallabel,
  email,
  Terms,
  Restrictions,
  HomeURL,
  Turl,
  mongoURI,
  salePlatformFee,
  rentalPlatformFee,
  furniturePlatformFee,
  publish_key,
  secret_key,
  Local_Url,
  Email_Pattern,
  Password_Pattern,
  is_Razorpay,
  is_Stripe,
  updateCity,
};
