import React, { useState, useEffect } from "react";
import Modal from "react-modal"; // Import the Modal component
import { API_URL, cities, propertylabel, furniturelabel ,updateCity} from "./ConstFile";
import { arrayBufferToBase64 } from "../utils/ImageBinaryConverter";
import { State, City } from "country-state-city";
import Select from "react-select";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Make sure to set the appElement for accessibility (e.g., for screen readers)
Modal.setAppElement("#root");
const HomeCityDropdownn = () => {
  const [property, setProperty] = useState([]);
  const [furniture, setFurniture] = useState([]);
  const [noItemsFound, setNoItemsFound] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [furnitureImage, setFurnitureImage] = useState("");
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [loadingFurniture, setLoadingFurniture] = useState(false);
  const defaultCountry = { id: 101, name: "India" };
  const [stateid, setstateid] = useState(0);
  const [stateSelected, setStateSelected] = useState(false);
  const [indianStates, setIndianStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCityInput, setSelectedCityInput] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCities, setStateCities] = useState([]);


  const cityMapping = updateCity;

  
  useEffect(() => {
    const indiaStates = State.getStatesOfCountry("IN");
    setIndianStates(indiaStates);
    setSelectedState();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      setLoadingProperty(true);
      setLoadingFurniture(true);
      getProperties(selectedCity, propertylabel);
      getProperties(selectedCity, furniturelabel);
    } else {
      setProperty([]);
      setFurniture([]);
      setNoItemsFound(false);
    }
  }, [selectedCity]);


  const handleStateChange = (item) => {
    setSelectedState(item);

    // Get cities for the selected state
    const cities = City.getCitiesOfState("IN", item.isoCode);
   
    // Check and update city names based on the mapping
    const updatedCities = cities.map((city) => ({
      ...city,
      name: cityMapping[city.name] || city.name,
    }));

    setStateCities(updatedCities);
  };
  const handleCityChange = (item) => {
    setSelectedCityInput(item);
    setSelectedCity(item.name);
  }

  const getProperties = async (
    selectedCityCheck = "",
    type = propertylabel
  ) => {
    let query = "";
    if (selectedCityCheck) {
      query = `?selectedCity=${selectedCityCheck}&&selectedParentBoxVal=${type}`;
      // console.log(selectedCityCheck);
      } else {
      return [];
    }
    let listings = await fetch(`${API_URL}users${query}`, {
      method: "GET",
    });
    listings = await listings.json();
    if (listings) {
      if (type === propertylabel) {
        setProperty(listings);
        setLoadingProperty(false);
      }
      if (type === furniturelabel) {
        setFurniture(listings);
        setLoadingFurniture(false);
      }
      setNoItemsFound(listings.length === 0);
    }
  };

  const openFurnitureModal = (product) => {
    setSelectedProduct(product);
    setFurnitureImage("");
    setIsModalOpen(true);

    if (product.selectedImageUrl && product.selectedImageUrl.data) {
      const base64String = arrayBufferToBase64(
        product.selectedImageUrl.data.data
      );
      setFurnitureImage(base64String);
    }
  };

  const closeFurnitureModal = () => {
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  return (
    <div className="container home-page">
      <div className="d-flex flex-wrap tx_dropdownn">
        <div className="col-12 col-md-4 col-lg-3 me-md-1 tx_dropdown">
          <Select
          options={indianStates}
          getOptionLabel={(options) => {
            return options["name"];
          }}
          getOptionValue={(options) => {
            return options["isoCode"];
          }}
          value={selectedState}
          placeholder="Select State"
          onChange={handleStateChange}
        />
        </div>
        <div className="col-12 col-md-4 col-lg-3 ms-md-1 mt-3 mt-md-0 tx_dropdown">
        <Select
       options={stateCities}
       getOptionLabel={(options) => {
         return options["name"];
       }}
        getOptionValue={(options) => {
          return options["name"];
        }}
        value={selectedCityInput}
          onChange={handleCityChange}
          placeholder="Select City"

      />
        </div>
      </div>
      {!selectedCity && <p className="mt-3">Please Select State City</p>}
      <div>
        <p className="label-home">Property</p>
      </div>
      {loadingProperty ? (
  <div className="text-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading Property...</span>
    </div>
  </div>
) : (
  <div className="d-flex flex-wrap py-2">
    {property.length === 0 ? (
      <p>No property found in the selected city</p>
    ) : (
      property.map((product, index) => {
        let base64String = "";
        
        if (product.selectedImageUrl && product.selectedImageUrl[0] && product.selectedImageUrl[0].data) {
          base64String = arrayBufferToBase64(product.selectedImageUrl[0].data.data);
        }

        return (
          <div
            className={
              product.selectedChildBoxVal === "Sale"
                ? "card tx_border"
                : "card"
            }
            onClick={() => openFurnitureModal(product)}
            key={index}
          >
            <img
              src={
                  product.selectedImageUrl[0] && product.selectedImageUrl[0].data
                    ? `data:image/png;base64,${base64String}`
                    : process.env.PUBLIC_URL + "/img/product-1.png"
                }
              alt={
                product.selectedImageUrl.data
                  ? "Selected Image Alt Text"
                  : "Default Image Alt Text"
              }
              className="product-image product-image_main"
            />
            <div className="product-title ">
                      {product.title.length > 15
                        ? product.title.substring(0, 25) + "..."
                        : product.title}
                    </div>
            <div className="type">{product.selectedChildBoxVal}</div>
          </div>
        );
      })
    )}
  </div>
)}
      <div>
        <p className="label-home">Furniture</p>
      </div>
     {loadingFurniture ? (
  <div className="text-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading Furniture...</span>
    </div>
  </div>
) : (
  <div className="d-flex flex-wrap py-2">
    {furniture.length === 0 ? (
      <p>No furniture found in the selected city</p>
    ) : (
      furniture.map((product, index) => {
        let base64String = "";
        
        if (product.selectedImageUrl && product.selectedImageUrl[0] && product.selectedImageUrl[0].data) {
          base64String = arrayBufferToBase64(product.selectedImageUrl[0].data.data);
        }
        
        return (
          <div className="card" key={index}>
            <div onClick={() => openFurnitureModal(product)}>
              <img
                src={
                  product.selectedImageUrl[0] && product.selectedImageUrl[0].data
                    ? `data:image/png;base64,${base64String}`
                    : process.env.PUBLIC_URL + "/img/product-1.png"
                }
                alt={
                  product.selectedImageUrl || "Default Image Alt Text"
                }
                className="product-image product-image_main"
              />
            </div>
            <div className="product-title">{product.title}</div>
          </div>
        );
      })
    )}
  </div>
)}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeFurnitureModal}
        contentLabel="Furniture Details Modal"
      >
        {selectedProduct && (
          <div>
            <h1 className="mb-4 detail_page_text text-break">{selectedProduct.title}</h1>
            <div className="close-modal" onClick={closeFurnitureModal}>
              <i class="fa-solid fa-xmark "></i>
            </div>
            <div className="row">
              <div className="col-12">
              <Carousel  className="Carousel_ mb-carousel">
              {selectedProduct.selectedImageUrl.map((productimg, index) => {
                let base64String = "";
                if (productimg && productimg.data) {
                  base64String = arrayBufferToBase64(
                    productimg.data.data
                  );
                }
                return(
                <div>
                    <img
                      src={
                        productimg.data
                          ? `data:image/png;base64,${base64String}`
                          : process.env.PUBLIC_URL + "/img/product-1.png"
                      }
                      alt="Indralokam"
                      className="product-image"
                    />
                    
                </div>

              )})}
               
            </Carousel>
              </div>
              <div className="col-12">
              {selectedProduct.locality && (
                <h5 className="mb-3 detail_page_text text-break">
                  <strong>Locality :</strong> {selectedProduct.locality}
                </h5>
                )}
                <h5 className="mb-3 detail_page_text text-break">
                  <strong>Type:</strong> {selectedProduct.selectedParentBoxVal}
                </h5>
                <h5 className="mb-3 detail_page_text text-break">
                  <strong>Description:</strong> {selectedProduct.description}
                </h5>
                <h5 className="mb-3 detail_page_text">
                  <strong>Price:</strong> ₹ {selectedProduct.price}
                </h5> 
                <h5 className="mb-3 detail_page_text">
                  <strong>Contact Number:</strong> {selectedProduct.number}
                </h5>                
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default HomeCityDropdownn;
