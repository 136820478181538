import React, { useState } from "react";
import Image_uploader from "./Image_uploader";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./ConstFile";
import { arrayBufferToBase64 } from "../utils/ImageBinaryConverter";
function EditProductValue({ products }) {
  const [selectedImageUrlNew, setSelectedImageUrl] = useState(null);
  const navigate = useNavigate();
  const [editedProducts, setEditedProducts] = useState({
    id: products._id,
    title: products.title,
    description: products.description,
    selectedImageUrl: products.selectedImageUrl,
  });
  const [isEdited, setIsEdited] = useState(false); // Initialize as false since there are no initial edits.
  const [isLoading, setIsLoading] = useState(false); // State to track loading state.

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedProducts((prevProducts) => ({
      ...prevProducts,
      [name]: value,
    }));
    setIsEdited(true); // Set isEdited to true when text changes.
  };
  const handleImageFile = (file) => {
    // console.log(file);
    setSelectedImageUrl(file);
    setIsEdited(true); // Set isEdited to true when a new image is added.
  }
  const handleOldImageDelete = async (imageIdToDelete) => {
    setIsEdited(true); // Set isEdited to true when an image is deleted.
    if (imageIdToDelete) {
      const updatedSelectedImages = editedProducts.selectedImageUrl.filter(
        (image) => image._id !== imageIdToDelete
      );
      setEditedProducts((prevProducts) => ({
        ...prevProducts,
        selectedImageUrl: updatedSelectedImages,
      }));
      // Make a request to the server to delete the image
      try {
        const response = await fetch(`${API_URL}deleteImage`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: editedProducts.id,
            imageIdToDelete,
          }),
        });
        if (response.status === 200) {
          // Image deletion was successful
          // You may want to display a success message or handle it in your UI
          console.log("Image deletion successful");
        } else {
          // Handle the error if the deletion was not successful
          console.error("Image deletion failed");
        }
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  };
  const handleSave = async () => {
    // Check if there are changes (e.g., title, description, or new images)
    if (
      editedProducts.title !== products.title ||
      editedProducts.description !== products.description ||
      (selectedImageUrlNew && selectedImageUrlNew.length > 0) ||
      isEdited
    ) {
      setIsLoading(true); // Show loader when saving starts.
      // Create a FormData object to send the data to the server
      const formData = new FormData();
      formData.append("id", editedProducts.id);
      formData.append("title", editedProducts.title);
      formData.append("description", editedProducts.description);
      if (selectedImageUrlNew) {
        // If a new image is selected, add it to the FormData
        for (let i = 0; i < selectedImageUrlNew.length; i++) {
          formData.append("image", selectedImageUrlNew[i]);
        }
      }
      setIsEdited(false); // Set isEdited back to false since changes are saved.
      // Send the FormData to the server to update the product
      const response = await fetch(`${API_URL}products`, {
        method: "POST",
        body: formData,
      });
      console.log(response);
      if (response.status === 200) {
        // navigate("/main-dashboard");
        navigate(0);
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <form action="#" className="mt-4 tx_form">
        <label className="form-label mt-4">Title</label>
        <br />
        <input
          type="text"
          name="title"
          value={editedProducts.title}
          onChange={handleInputChange}
        />
        <label className="form-label mt-4">Description</label>
        <br />
        <textarea
          name="description"
          rows="4"
          value={editedProducts.description}
          onChange={handleInputChange}
        ></textarea>
        <div className="upload-files-container">
          {editedProducts.selectedImageUrl && (
            <div className="image-preview">
              {editedProducts.selectedImageUrl.map((productimg, index) => {
                let base64String = "";
                if (productimg && productimg.data) {
                  base64String = arrayBufferToBase64(productimg.data.data);
                }
                return (
                  <div key={index}>
                    <img
                      src={
                        productimg.data
                          ? `data:image/png;base64,${base64String}`
                          : process.env.PUBLIC_URL + "/img/product-1.png"
                      }
                      alt="Indralokam"
                      className="product-image"
                    />
                    <button
                      type="button"
                      className="delete-image-button"
                      onClick={() => handleOldImageDelete(productimg._id)}
                    >
                      X
                    </button>
                  </div>
                );
              })}
              {selectedImageUrlNew && (
                <>
                  {/* {selectedImageUrlNew.map((productimg, index) => {
                    // console.log("productimg");
                    // console.log(productimg);
                    return (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(productimg)}
                          alt="Indralokam"
                          className="product-image"
                        />
                        <button
                          type="button"
                          className="delete-image-button"
                          onClick={() =>
                            setSelectedImageUrl((prev) =>
                              prev.filter((_, i) => i !== index)
                            )
                          }
                        >
                          X
                        </button>
                      </div>
                    );
                  })} */}
                </>
              )}
            </div>
          )}
          <Image_uploader  selectedImageFiles={handleImageFile} disabled={isEdited} />
        </div>
        <div className="summit-btnn mt-4">
          {isLoading ? ( // Conditional rendering based on isLoading state.
            <div className="tx_spinner">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="course-payment-button Form_submit"
              onClick={handleSave}
              disabled={!isEdited}
            >
              SAVE
            </button>
          )}
        </div>
      </form>
    </>
  );
}

export default EditProductValue;