import React from "react";
import { Link } from "react-router-dom";
import { email, Turl } from './ConstFile';
import ImageLoader from "./Imageloader";

function Footer() {
  return (
    <>
      <div className="footer container-fluid">
        <div className=" container p-sm-5 p-3">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 mt-4 mt-md-0 footer_image_css">
            <ImageLoader src="./img/big-logo.jpg" height="349" width="349" alt="Indralokam" />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4 mt-4 mt-md-0">
              <h4 className="footer_lable">FOR USERS</h4>
              <div className="mt-5">
                <p>
                  {" "}
                  <Link to="about" className="footer_text">
                    About Us
                  </Link>
                </p>
                <p>
                  {" "}
                  <Link to="faq" className="footer_text">
                    FAQ
                  </Link>{" "}
                </p>
                <p>
                  {" "}
                  {/* <Link to="termsofuse" className="footer_text"> */}
                   <a className="footer_text" href={Turl} target="_blank">Terms & Conditions</a> 
                  {/* </Link> */}
                </p>
                <p>
                  {" "}
                  {/* <Link to="privacypolicy" className="footer_text"> */}
                    <a className="footer_text" href="https://merchant.razorpay.com/policy/MX4baEISgOx0p1/privacy" target="_blank">Privacy Policy</a> 
                  {/* </Link> */}
                </p>
                <p>
                  {" "}
                  {/* <Link to="refundandcancle" className="footer_text"> */}
                   <a className="footer_text" href="https://merchant.razorpay.com/policy/MX4baEISgOx0p1/refund" target="_blank">Refund & Cancellation</a> 
                  {/* </Link> */}
                </p>
                <p>
                  {" "}
                  {/* <Link to="refundandcancle" className="footer_text"> */}
                   <a className="footer_text" href="https://merchant.razorpay.com/policy/MX4baEISgOx0p1/shipping" target="_blank">Shipping</a> 
                  {/* </Link> */}
                </p>
              </div>
            </div>
           {/* <div className="col-xs-12 col-sm-3 col-md-3 col-lg-4 mt-4 mt-md-0">
              <h4 className="footer_lable">FIND US HERE</h4>
              <div className="mt-5">
                <div>
                  <div className="d-flex">
                    <i class="fa-solid fa-location-dot footer_icon"></i>
                    <p className="footer_text ms-3">ABC City, xyz Road </p>
                  </div>
                  
                  <div className="d-flex mt-4">
                    <i class="fa-solid fa-phone footer_icon"></i>
                    <p className="footer_text ms-3">1234567890</p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-xs-12 col-sm-12 col-md-4 mt-4 mt-md-0">
              <h4 className="footer_lable">CONTACT US</h4>
               
              <div className="d-flex align-items-center mt-5 mb-3">
                    <i class="fa-regular fa-envelope footer_icon"></i>
                    <p className="footer_text ms-3 mb-0">{email}</p>
                  </div>
                  <div className="d-flex my-2">
                <i class="fa-solid fa-location-dot footer_icon"></i>
                {/* <p className="footer_text ms-3">{address}</p> */}
                <p className="footer_text ms-3">F4 Sairam Apartments, Sector-1, MVP colony,<br /> Visakhapatnam, Andhra Pradesh, India 530017</p>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2259.5195667474145!2d83.32779974106117!3d17.74257490737457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a39433cd2547551%3A0xceb7dd2de8f23055!2sSai%20Ram%20Apartments!5e0!3m2!1sen!2sin!4v1695114665463!5m2!1sen!2sin" allowfullscreen="" loading="lazy" style={{width:"100%"}} referrerpolicy="no-referrer-when-downgrade"></iframe>
              {/* <div className="mt-5">
                <div className="d-flex mb-4 social_icons">
                  <a href="">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                  <p className="footer_text ms-3">Indralokam</p>
                </div>
                <div className="d-flex mb-4 social_icons">
                  <a href="">
                    <i class="fa-brands fa-facebook"></i>
                  </a>
                  <p className="footer_text ms-3">Indralokam</p>
                </div>
                <div className="d-flex social_icons">
                  <a
                    href="https://api.whatsapp.com/send?phone=8580487180"
                    class="tx_icons"
                    role="button"
                    onclick="dataLayer.push( { 'event': 'whatsapp', 'position': 'header' } );"
                  >
                    <span>
                      <i class="fa-brands fa-whatsapp"></i>
                    </span>
                  </a>
                  <p className="footer_text ms-3">1234567890</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
