import React, { useState, useEffect } from "react";
import {API_URL,email } from "./ConstFile";

const FAQSection = () => {
  const [faqItems, setFaqItems] = useState([]);

  useEffect(() => {
    async function fetchFAQ() {
      try {
        const response = await fetch(`${API_URL}faq`);
        const data = await response.json();
        setFaqItems(data);
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    }

    fetchFAQ();
  }, []);

  const toggleAnswer = (id) => {
    setFaqItems((prevItems) =>
      prevItems.map((item) =>
        item._id === id ? { ...item, isOpen: !item.isOpen } : item
      )
    );
  };

  return (
    <>
      <div className="hero_sec_pages">
        <h1 className="hero_heading">FAQ</h1>
      </div>
      <div className="faq-section p-md-5">
        <h1 className="page-title">Frequently Asked Questions</h1>
        <div className="faq-list">
          {faqItems.map((item) => (
            <div key={item._id} className="faq-item">
              <div
                className={`faq-question ${item.isOpen ? "open" : ""}`}
                onClick={() => toggleAnswer(item._id)}
              >
                {item.question}
              </div>
              {item.isOpen && <div className="faq-answer ">{item.answer}</div>}
            </div>
          ))}
        </div>
        <div>
          If you require any assistance, please don't hesitate to contact us via
          email at <b>{email}</b>
        </div>
      </div>
    </>
  );
};

export default FAQSection;
