import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Validation from "./LoginValidation";
import axios from "axios";
import { API_URL, Terms } from "./ConstFile";

function Login({ onSwitchToSignup }) {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [isChecked, setIsChecked] = useState(false);  
  const [validationError, setValidationError] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckboxChange = () => {
    if (!isChecked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(Validation(values));
  
    var sucMsg = document.getElementsByClassName("successclass");
    var errMsg = document.getElementsByClassName("dangerclass1");
    const errMsgNC = document.getElementsByClassName("dangerclass");
    for (var i = 0; i < errMsg.length; i++) {
      errMsg[i].style.display = "none";
    }
    for (var i = 0; i < sucMsg.length; i++) {
      sucMsg[i].style.display = "none";
    }
    for (var i = 0; i < errMsgNC.length; i++) {
      errMsgNC[i].style.display = "none";
    }
  
    if (isChecked) {
      setErrors(Validation(values));
      if (
        (errors.email === undefined || errors.email === "") &&
        (errors.password === undefined || errors.password === "")
      ) {
        axios
          .post(`${API_URL}login`, values)
          .then((res) => {
            if (res.data.length) {
              if(res.data == "Invalid credentials") {
                setValidationError();
                for (var i = 0; i < errMsg.length; i++) {
                  errMsg[i].style.display = "block";
                }
              } else if (res.data == "User is not confirmed") {
                setValidationError();
                for (var i = 0; i < errMsg.length; i++) {
                  errMsgNC[i].style.display = "block";
                }
              } else{
                localStorage.setItem("user", JSON.stringify(res.data[0]));
                setValidationError();
                setIsLoggedin(true);
                for (var i = 0; i < sucMsg.length; i++) {
                  sucMsg[i].style.display = "block";
                }
                window.location="/main-dashboard";
              }
            } else {
              for (var i = 0; i < errMsg.length; i++) {
                errMsg[i].style.display = "block";
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setValidationError("Please Enter Valid Email Id & Password");
          });
      }
    } else {
      setValidationError("Please agree to our terms and conditions to proceed");
    }
  };
  
  return (
    <div className={`d-flex login-div mt-2 mb-2`}>
      <div className="bg-white  rounded w-100">
        <form className="tx_form" action="" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="mb-2" htmlFor="email">
              <strong className="label ">Email</strong>
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.email && (
              <span className="text-danger"> {errors.email} </span>
            )}
          </div>
          <div className="mb-3">
            <label className=" mb-2" htmlFor="password">
              <strong className="label">Password</strong>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.password && (
              <span className="text-danger">{errors.password}</span>
            )}
          </div>
          <p className="mt-2 checkbox-signup">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            ></input>
             <p dangerouslySetInnerHTML={{ __html: Terms }}></p>
          </p>
          {validationError && <p style={{ color: "red" }}>{validationError}</p>}
          <button type="submit" className="tx_formbtnn my-2">
            <strong>Log in</strong>
          </button>
          <Link
            to="/"
            className="btn btn-default box_btnn border w-100 bg-light rounded-0 text-decoration-none"
            onClick={onSwitchToSignup} // Call onSwitchToSignup when clicked
          >
            Create Account
          </Link>
          <div
            class="alert alert-success successclass mt-4 text-center btn-d-none"
            role="alert"
          >
            Logged In Successfully!
          </div>
          <div
            class=" alert alert-danger dangerclass mt-4 text-center btn-d-none"
            role="alert"
          >
            Please complete your email verification & try again
          </div>
          <div
            class="alert alert-danger dangerclass1 mt-4 text-center btn-d-none"
            role="alert"
          >
            Please Enter Valid Email Id & Password
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
