import React from 'react';

const CardUIWithSelection = ({ onSelectPrice, selectedCity, selectedSlot }) => {
  const slotData = [
    { id: 1, title: 'Slot 1', price: '1000' },
    { id: 2, title: 'Slot 2', price: '800' },
    { id: 3, title: 'Slot 3', price: '700' },
    { id: 4, title: 'Slot 4', price: '600' },
    { id: 5, title: 'Slot 5', price: '500' },
  ];

  const handleValueSelect = (value) => {
    onSelectPrice(value);
    
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        {slotData.map((card) => (
          <div
            key={card.id}
            className={`slot ${selectedSlot === card.title ? 'selected' : ''}`}
            onClick={() => handleValueSelect(card.title)}
          >
            <p>{card.title}</p>
          </div>
        ))}
      </div>
      {selectedSlot && (
        <p className='select_item mt-2'>
          A slot can be bought for 1 month
        </p>
      )}
    </div>
  );
};

export default CardUIWithSelection;
