import React from "react";
import HomeCityDropdownn from "./HomeCityDropdown";

const Dropdowncontent = () => {
  return (
   <div className="container-fluid px-0 py-5 p-sm-5">
      <HomeCityDropdownn />
   </div>
  );
};
export default Dropdowncontent;
