import React, { useState } from "react";
import { Link } from "react-router-dom";
import Validation from "./SignupValidation";
import axios from "axios";
import { API_URL,Terms, Restrictions } from "./ConstFile";
import PasswordStrengthBar from 'react-password-strength-bar';

export default function Signup({ onClose, onSwitchToLogin }) {
  const [isChecked, setIsChecked] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  
  const handleCheckboxChange = () => {
    if (!isChecked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };
  const handleCheckbox2Change = () => {
    if (!isPrivacy) {
      setIsPrivacy(true);
    } else {
      setIsPrivacy(false);
    }
  };

  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const handleInput = (event) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === 'password') {
      setPassword(event.target.value); // Update the password state
    } else if (event.target.name === 'confirmPassword') {
      setConfirmPassword(event.target.value); // Update the confirm password state
    }
  };
  const handleSubmit = async (event) => {
	  event.preventDefault();
	  var sucMsg = document.getElementsByClassName("alert-success");
	  var errMsg = document.getElementsByClassName("alert-danger");
	  var form = document.getElementsByClassName("tx_form");
	  for (var i = 0; i < errMsg.length; i++) {
	    errMsg[i].style.display = "none";
	  }
	  for (var i = 0; i < sucMsg.length; i++) {
	    sucMsg[i].style.display = "none";
	  }
    if (isChecked && isPrivacy) {
      const validationErrors = Validation(values);
      setErrors(validationErrors);

	    if (!validationErrors.name && !validationErrors.email && !validationErrors.password && !validationErrors.confirmPassword) {
        if (password !== confirmPassword) {
          setValidationError("Passwords do not match");
          return;
        }
        try {
	        const res = await axios.post(`${API_URL}Signup`, values);
	        if (res.data.success) {
	          for (var i = 0; i < sucMsg.length; i++) {
	            sucMsg[i].style.display = "block";
	            form[i].style.display = "none";
	            errMsg[i].innerHTML = res.data.message; // Use innerHTML to set the message
	          }
	          
	        } else {
	          for (var i = 0; i < errMsg.length; i++) {
	            errMsg[i].style.display = "block";
	            form[i].style.display = "none";
	            errMsg[i].innerHTML = res.data.message; // Use innerHTML to set the message
	          }
	        }
	      } catch (error) {
	        console.error(error);
	      }
	    }
	  } else if (isChecked === true && isPrivacy === false) {
	    setValidationError("Please agree to our terms and conditions to proceed");
	  } else if (isChecked === false && isPrivacy === true) {
	    setValidationError(Restrictions);
	  } else {
	    setValidationError("* Please select the mandatory checkboxes.");
	  }
	}; 
  
  return (
    <div className="d-flex ">
      <div className="bg-white w-100 mt-5 mb-5">
        <form className="tx_form" action="" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="label mb-2" htmlFor="name">
              <strong>Name</strong>
            </label>
            <input
              type="text"
              placeholder="Enter Name"
              name="name"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.name && <span className="text-danger">{errors.name}</span>}
          </div>
          <div className="mb-3">
            <label className="label mb-2" htmlFor="email">
              <strong>Email</strong>
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </div>
          <div className="mb-3">
            <label className="label mb-2" htmlFor="password">
              <strong>Password</strong>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.password && (
              <small className="text-danger white-space">{errors.password}</small>
            )}
          <PasswordStrengthBar password={password} className="custom-strength-bar"/>
          </div>
          <div className="mb-3">
            <label className="label mb-2" htmlFor="confirmPassword">
              <strong>Confirm Password</strong>
            </label>
            <input
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.confirmPassword && (
              <small className="text-danger white-space">{errors.confirmPassword}</small>
            )}
            <PasswordStrengthBar password={confirmPassword} className="custom-strength-bar"/>
          </div>
          <p className="mt-3 checkbox-signup">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            ></input>
            {Restrictions}
          </p>
          <p className="mt-3 checkbox-signup">
            <input
              type="checkbox"
              checked={isPrivacy}
              onChange={handleCheckbox2Change}
            ></input>
            <span dangerouslySetInnerHTML={{ __html: Terms }}></span>
          </p>
          {validationError && <p style={{ color: "red" }}>{validationError}</p>}

          <button type="submit" className="tx_formbtnn">
            <strong>Sign up</strong>
          </button>

          <Link
            to="/"
            className="btn box_btnn btn-default border w-100 bg-light rounded-0 text-decoration-none"
            onClick={onSwitchToLogin}
          >
            Login Page
          </Link>
        </form>
          <div
            class="alert alert-success mt-4 text-center btn-d-none"
            role="alert"
          >
            An email containing the account activation link has been sent to you. Kindly check your inbox and click on the provided link to verify your email.{" "}
          </div>
          <div
            class="alert alert-danger mt-4 text-center btn-d-none"
            role="alert"
          >
            Email is already Registered!
          </div>
          
      </div>
    </div>
  );
}