import React, { useEffect, useState } from "react";
import Model from "react-modal";
import { Link, useLocation } from "react-router-dom";
import Startalisting from "./Startalisting";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import ImageLoader from "./Imageloader";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

export default function Navbar() {
  const [loginFormOpen, setLoginFormOpen] = useState(false);
  const [registerFormOpen, setRegisterFormOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const Auth = localStorage.getItem("user");
  const [isLoggedin, setIsLoggedin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const refreshPage = () => {
    if (location.pathname !== "/") {
      navigate("/");
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (Auth) {
      setLoginFormOpen(false);
    }
    $(document).ready(function () {
      $(".nav-item").click(function () {
        $("div").removeClass("show");
      });
    });
    $(document).ready(function () {
      $("body").click(function (event) {
        if (!$(event.target).closest("div.show").length) {
          $("div.show").removeClass("show");
        }
      });
    });
  }, []);

  const handleSwitchToSignup = () => {
    setLoginFormOpen(false);
    setRegisterFormOpen(true);
  };
  const forgotPassword = (event) => {
    setLoginFormOpen(false);
    navigate("/forgot-password");
  };
  const handleFormClose = () => {
    setFormOpen(false);
  };
  const handleSwitchToLogin = () => {
    setRegisterFormOpen(false);
    setLoginFormOpen(true);
  };
  const logout = () => {
    localStorage.removeItem("user");
    setIsLoggedin(false);
    navigate("/home");
    navigate(0);
  };

  const startListingCheck = () => {
    if (Auth) {
      setFormOpen(true);
    } else {
      setLoginFormOpen(true);
    }
  };

  return (
    <div className="container-fluid py-4 tx_navv">
      <div className="container">
        <nav className="navbar navbar-expand-lg p-0">
          <Link to="" className="navbar-brand" onClick={refreshPage}>
            <ImageLoader
              src="./img/big-logo.jpg"
              height="80"
              width="80"
              alt="Indralokam"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="" className="nav-link text-menu-color">
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link to="about" className="nav-link text-menu-color">
                  About Us
                </Link>
              </li>

              <li className="nav-item">
                <Link to="faq" className="nav-link text-menu-color">
                  FAQ
                </Link>
              </li>

              {Auth ? (
                <>
                  <li className="nav-item">
                    <Model
                      isOpen={loginFormOpen}
                      onRequestClose={() => setLoginFormOpen(false)}
                    >
                      <Login
                        onClose={() => setLoginFormOpen(false)}
                        onSwitchToSignup={handleSwitchToSignup}
                      />
                    </Model>
                  </li>
                  <li className="nav-item">
                    <Link to="/main-dashboard">
                      <a
                        to="/setting"
                        activeClassName="active-link preselected-link"
                        className="nav-link text-menu-color"
                      >
                        Dashboard
                      </a>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="invite" className="nav-link text-menu-color">
                      Invite
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/"
                      onClick={logout}
                      className="nav-link text-menu-color"
                    >
                      Logout
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to="/" onClick={() => setLoginFormOpen(true)}>
                      <a className="nav-link text-menu-color">Login</a>
                    </Link>
                    <Model
                      className={"login_custom"}
                      isOpen={loginFormOpen}
                      onRequestClose={() => setLoginFormOpen(false)}
                    >
                      <div className="d-flex justify-content-between">
                        <div
                          className="close-modal"
                          onClick={() => setLoginFormOpen(false)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                      <Login
                        onClose={() => setLoginFormOpen(false)}
                        onSwitchToSignup={handleSwitchToSignup}
                      />
                      <div className="forgetPassword" onClick={forgotPassword}>Forgot Password</div>
                    </Model>
                  </li>
                  <li className="nav-item">
                    <Link to="/" onClick={() => setRegisterFormOpen(true)}>
                      <a className="nav-link text-menu-color">Register</a>
                    </Link>
                    <Model
                      className={"register_custom"}
                      isOpen={registerFormOpen}
                      onRequestClose={() => setRegisterFormOpen(false)}
                    >
                      <div className="d-flex justify-content-between">
                        <div
                          className="close-modal"
                          onClick={() => setRegisterFormOpen(false)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </div>
                      </div>
                      <Signup
                        onClose={() => setRegisterFormOpen(false)}
                        onSwitchToLogin={handleSwitchToLogin}
                      />
                    </Model>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="listing-btnn">
            <button className="tx_listing" onClick={startListingCheck}>
              {" "}
              START A LISTING
            </button>
            <Model isOpen={formOpen}>
              <div className="d-flex justify-content-between">
                <div
                  className="close-modal closeMain"
                  onClick={handleFormClose}
                >
                  <i className="fa-solid fa-xmark"></i>
                </div>
              </div>
              <Startalisting />
            </Model>
          </div>
        </nav>
      </div>
    </div>
  );
}
