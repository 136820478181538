import React, { useState, useEffect } from "react";
import CityDropdown from "./CityDropdown";
import CardUIWithSelection from "./CardUIWithSelection";
import BoxUIWithSelection from "./BoxUIWithSelection";
import DateRangePicker from "./DateRangePicker ";
import displayRazorpay from "../utils/PaymentGateway";
import Image_uploader from "./Image_uploader";
import { API_URL,salePlatformFee,rentalPlatformFee,furniturePlatformFee } from "./ConstFile";
import { useNavigate } from "react-router-dom";
import PaymentGatewayPage from "./PaymentGatewayPage";

function Startalisting(props) {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [locality, setLocality] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [PlatformFee, setPlatformFee] = useState("");
  const [number, setNumber] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedParentBoxVal, setSelectedParentBoxVal] = useState("");
  const [selectedChildBoxVal, setSelectedChildBoxVal] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [error, setError] = useState(false);
  const [excludeDates, setExcludeDates] = useState(null);
  const [text, setText] = useState("");
  const [showInfo, setShowInfoValue] = useState();
  const [user_id, setUser_id] = useState("");
  const [showSlot, setShowSlot] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(true);
  const [isLoaderOpen, setIsLoaderOpen] = useState(true);
  const Auth = localStorage.getItem("user");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null); // Track selected slot
  const [slot, setSlot] = useState("");
  const [payValuePrice, sePayValuePrice] = useState("");
  const [paymentResultId, setPaymentResultId] = useState("");
  const [Newtitle, setNewtitle] = useState('');
  const [productID, setProductID] = useState('');

  const handleDateChange = (dates) => {
    setSelectedStartDate(dates.formStartDate);
    setSelectedEndDate(dates.formEndDate);
  };

  const handleSlotSelect = async (title) => {
    console.log(excludeDates);
    setSelectedSlot(title); // Update selected slot
    setSlot(title); // Update selected slot
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    let existingSlotDates = [];
    if (title) {
      setIsLoading(true);
      try {
        let query = `slotTitle=${title}&selectedCity=${selectedCity}&selectedParentBoxVal=${selectedParentBoxVal}`;
        let getSlotDates = await fetch(`${API_URL}users?${query}`, {
          method: "GET",
        });
        getSlotDates = await getSlotDates.json();
        if (getSlotDates) {
          getSlotDates.map((item, index) => {
            if (item.selectedStartDate && item.paymentStatus === true) {
              var listStartDate = new Date(
                getFormattedDate(item.selectedStartDate)
              );
              var listEndDate = new Date(
                getFormattedDate(item.selectedEndDate)
              );

              existingSlotDates = existingSlotDates.concat(
                getDatesInRange(listStartDate, listEndDate)
              );
            }
          });
          setExcludeDates(existingSlotDates);
        } else {
          handleDateChange({ formStartDate: null, formEndDate: null });
        }
      } catch (error) {
        console.error("Error fetching slot data:", error);
      } finally {
        setIsLoading(false);
      }
    }

    setUser_id(Auth ? JSON.parse(Auth)._id : "");

    if (title) {
      setShowSlot(true);
    } else {
      setShowSlot(false);
    }
  };

  const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());

    date.setDate(date.getDate());

    const dates = [];

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };
  const getFormattedDate = (selectedStartDate) => {
    if (selectedStartDate) {
      const start = new Date(selectedStartDate);
      const day = String(start.getDate()).padStart(2, "0");
      const month = String(start.getMonth() + 1).padStart(2, "0");
      const year = String(start.getFullYear()).slice(-2);
      const formattedStartDate = `${month}/${day}/${year}`;

      return formattedStartDate;
    }

    return selectedStartDate;
  };

  const handleImageFile = (files) => {
    setSelectedImageUrl(files); // Set the selected images to the newly uploaded images
  };


  const handleCloseRedirect = () => {
    navigate("/main-dashboard");
    navigate(0);
  };

  const handlePayment = async (e) => {
    
    e.preventDefault();

    let payValue;
    if (selectedParentBoxVal === "Property") {
      if (selectedChildBoxVal === "Sale") {
        payValue = salePlatformFee;
      } else {
        payValue = rentalPlatformFee;
      }
    } else {
      payValue = furniturePlatformFee;
    }
    const slotPriceAfterCal = (price * payValue) / 100;
    sePayValuePrice(slotPriceAfterCal)
    setNewtitle(title)

    if (
      !title ||
      !selectedCity ||
      !selectedState ||
      !description ||
      !selectedParentBoxVal ||
      !price ||
      !number ||
      !selectedSlot ||
      !selectedImageUrl.length ||
      !selectedStartDate
    ) {
      setError(true);

      return false;
    }
    var errMsg = document.getElementsByClassName("closeMain");
    const errMsgNC = document.getElementsByClassName("closeInner");
    for (var i = 0; i < errMsg.length; i++) {
      errMsg[i].style.display = "none";
    }
    for (var i = 0; i < errMsgNC.length; i++) {
      errMsgNC[i].style.display = "block";
    }
    setIsFormOpen(false);

    const formData = new FormData();
    for (let i = 0; i < selectedImageUrl.length; i++) {
      formData.append("image", selectedImageUrl[i]);
    }
    formData.append("title", title);
    formData.append("locality", locality);
    formData.append("price", price);
    formData.append("PlatformFee", PlatformFee);
    formData.append("number", number);
    formData.append("description", description);
    formData.append("selectedCity", selectedCity);
    formData.append("selectedState", selectedState);
    formData.append("selectedParentBoxVal", selectedParentBoxVal);
    formData.append("selectedChildBoxVal", selectedChildBoxVal);
    formData.append("selectedEndDate", selectedEndDate);
    formData.append("selectedStartDate", selectedStartDate);
    formData.append("user_id", user_id);
    formData.append("slot", selectedSlot);

    try {
      const response = await fetch(`${API_URL}register`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      setPaymentResultId(result._id)
      if (result) {
        setDescription("");
        setTitle("");
        setLocality("");
        setPrice("");
        setPlatformFee("");
        setNumber("");
        setSelectedSlot("");
        setSelectedImageUrl([]);
        setSelectedStartDate("");
        setProductID(result._id);
        setTimeout(() => {
          setIsLoaderOpen(false);
        }, 2000);
        //displayRazorpay(slotPriceAfterCal, result._id);
        // document.getElementsByClassName("form-heading")[0].style.display =
        //   "none";
      }
    } catch (error) {
      console.error("Error:", error);
    }

  };

  const handleRefresh = () => {
    navigate("/main-dashboard");
    navigate(0);
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setSelectedSlot(null);
    setShowSlot(false);
  };

  const handleStateSelect = (state) => {
    setSelectedState(state);
  };

  const handleBoxValues = (data) => {
    setSelectedParentBoxVal(data.selectedValue);
    setSelectedChildBoxVal(data.boxLabel);
    setPrice("");
    setText("");
  };

  const handlePriceChange = (e) => {
    const inputPrice = e.target.value;
    const newPrice = inputPrice.replace(/[^0-9]/g, '');
  
    setPrice(newPrice);
  
    if (newPrice) {
      setShowInfoValue(true);
    } else {
      setShowInfoValue(false);
    }
  
    let payValue;
    if (selectedParentBoxVal === "Property") {
      if (selectedChildBoxVal === "Sale") {
        payValue = salePlatformFee;
      } else {
        payValue = rentalPlatformFee;
      }
    } else {
      payValue = furniturePlatformFee;
    }
  
    const priceAfterCal = (parseFloat(newPrice) * payValue) / 100;
    setText("Platform Fee "+"₹" +" "+priceAfterCal);
    //console.log(setPlatformFee);
    setPlatformFee(priceAfterCal);
  };
  
  return (
    <>
      <div className="close-modal closeInner" onClick={handleCloseRedirect}>
        <i class="fa-solid fa-xmark "></i>
      </div>
      {isFormOpen ? (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="form-heading"><b>Enter The Details</b></h2>
          </div>
          <form action="" className="mt-4 tx_form">
            <div className="select-location mt-4">
              <CityDropdown
                onCitySelect={handleCitySelect}
                onStateSelect={handleStateSelect}
              />
            </div>
            {error && !selectedCity && (
              <span className="input-error">City is required.</span>
            )}
            <label className="form-label mt-2" htmlFor="Locality">
              Locality<span className="required-error"></span>
            </label>{" "}
            <br />
            <input
              type="text"
              name="Locality"
              id="B-name"
              value={locality}
              onChange={(e) => setLocality(e.target.value)}
              required
            />
            <div className="select_product mt-4">
              <p className="label mb-1">Select Your Option</p>
              <BoxUIWithSelection boxValues={handleBoxValues} />
            </div>
            {error && !selectedParentBoxVal && (
              <span className="input-error">Option is required.</span>
            )}
            <label className="form-label mt-4" htmlFor="businessname">
              Title<span className="required-error">*</span>
            </label>{" "}
            <br />
            <input
              type="text"
              name="businessname"
              id="B-name"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            {error && !title && (
              <span className="input-error">Title is required.</span>
            )}
            <label className="form-label mt-4" htmlFor="price">
              Price <span className="required-error">*</span>
            </label>{" "}
            <br />
            <div className="currency-wrap">
              <span className="currency-code"> ₹ </span>
              <input
              type="text"  
              name="price"
              id="price"
              value={price}
              onChange={handlePriceChange}
              required
              className="text-currency"
            />
            </div>
            {error && !price && (
              <span className="input-error">Price is required.</span>
            )}
            <div style={{ display: showInfo ? "block" : "none" }} >
              {text} {showInfo}
            </div>
            <label className="form-label mt-4" htmlFor="description">
              Description <span className="required-error">*</span>
            </label>{" "}
            <br />
            <textarea
              name="description"
              id="F-description"
              rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
            {error && !description && (
              <span className="input-error">Description is required.</span>
            )}
            <label className="form-label mt-4" htmlFor="price">
              Contact Number<span className="required-error">*</span>
            </label>{" "}
            <br />
            <div className="number_input">
            <span className="num"> +91 </span>
            <input            
              type="text"
              name="number"
              id="number"
              value={number}
              onChange={(e) => {
                const inputNumber = e.target.value.replace(/[^0-9]/g, '');
                if (inputNumber.length <= 10 && /^\d*$/.test(inputNumber)) {
                  setNumber(inputNumber);
                }
              }}
              required
              maxLength="10"
            />
            </div>
            {error && !number && (
              <span className="input-error">Number is required </span>
            )}
            <label className="form-label mt-4" htmlFor="images">
            Select image(s)
            </label>{" "}
            <br />
            <div className="upload-files-container">
  <Image_uploader selectedImageFiles={handleImageFile} />
</div>
            {error && !selectedImageUrl.length && (
              <span className="input-error">Image is compulsory.</span>
            )}
            <div className="slots mt-4">
              <p className="label mb-1">Select Slot</p>
              <CardUIWithSelection
                onSelectPrice={handleSlotSelect}
                selectedCity={selectedCity}
                selectedSlot={selectedSlot}
              />
              {error && !slot && (
                <span className="input-error">Slot is required.</span>
              )}
            </div>
            <div className="timing mt-4">
              <div style={{ display: isLoading ? "block" : "none" }}>
              <p className="label mb-1">Select Your Date</p>
                {" "}
                <div className="text-center py-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </div>
              <div style={{ display: showSlot ? "block" : "none" }}>
              <p className="label mb-1">Select Your Date</p>
                <DateRangePicker
                  onSelectDates={handleDateChange}
                  excludeDates={excludeDates}
                />
                {error && !selectedStartDate && (
                  <span className="input-error">Date is required.</span>
                )}
              </div>
            </div>
            <button
              type="submit"
              onClick={handlePayment}
              className="course-payment-button Form_submit mt-4"
            >
              SUBMIT
            </button>
          </form>
        </>
      ) : (
        <>
        {isLoaderOpen ? (
          <div className="tx_spinner_listing">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="payment-tab">
            <PaymentGatewayPage  paymentData={{title: Newtitle,price: payValuePrice, productID: productID}}/>
          </div>

          )}
       </>
      )}
    </>
  );
}

export default Startalisting;