import { Email_Pattern, Password_Pattern } from "./ConstFile";

function Validation(values) {
   let error = {};

   if (values.name === "") {
     error.name = "Name should not be empty";
   } else {
     error.name = "";
   }
 
   if (values.email === "") {
     error.email = "Email should not be empty";
   } else if (!Email_Pattern.test(values.email)) {
     error.email = "Invalid email format";
   } else {
     error.email = "";
   }
 
   if (values.password === "") {
     error.password = "Password should not be empty";
    } 
   else if (values.password[0].length >= 20) {
    error.password = "Password length should not exceed 20 characters";
   }
  //  else if (!passwordPattern.test(values.password)) {
  //   error.password = "The password must consist of minimum 8 characters including special characters and it should not surpass a maximum length of 20 characters";
  // }
  else if (!Password_Pattern.test(values.password)) {
    error.password = 
        <small className="text-danger">
            Password requirements:
          <ul>
            <li>Minimum 8 characters</li>
            <li>Maximum 20 characters</li>
            <li>At least 1 lowercase letter (a-z)</li>
            <li>At least 1 uppercase letter (A-Z)</li>
            <li>At least 1 numeric character (0-9)</li>
            <li>At least 1 special character(~!@#$()-_+={}[]|\;)</li>
          </ul>
        </small>;
  }
  
   else {
     error.password = "";
   }
   return error;
 }
 
 export default Validation;
 