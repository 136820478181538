
import React, { useState, useEffect } from 'react';
import Resizer from 'react-image-file-resizer';

const ImageUploader = (props) => {
  const [dragging, setDragging] = useState(false);
  const [validImageFiles, setValidImageFiles] = useState([]);

  // Hide image preview if className is "hideOnEdit" (and potentially show it again if it changes)
  const [showImagePre, setShowImagePre] = useState(true);
  
  useEffect(() => {
    setShowImagePre(props.className !== "hideOnEdit");
  }, [props.className]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload(files);
    }
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const handleFileUpload = (files) => {
    const updatedValidImageFiles = [...validImageFiles];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.startsWith('image/')) {
        Resizer.imageFileResizer(
          file,
          490,
          325,
          'JPEG',
          100,
          0,
          (resizedFile) => {
            updatedValidImageFiles.push(resizedFile);
            setValidImageFiles(updatedValidImageFiles);
            props.selectedImageFiles(updatedValidImageFiles);
          },
          'blob'
        );
      } else {
        alert('Please select only image files.');
      }
    }
  };

  const handleImageDelete = (index) => {
    const updatedValidImageFiles = [...validImageFiles];
    updatedValidImageFiles.splice(index, 1); // Fix: Modify the copied array
    setValidImageFiles(updatedValidImageFiles); // Set the state with the modified array
    props.selectedImageFiles(updatedValidImageFiles); // Pass the modified array
  };

  return (
    <>
    <div className='imagesss'>
    {showImagePre && validImageFiles.length > 0 && (
        <div className="image-preview">
          {validImageFiles.map((file, index) => (
            <div key={index}>
              <img
                src={URL.createObjectURL(file)}
                alt="Uploaded"
                className="product-image"
              />
              <button
                type="button"
                className="delete-image-button"
                onClick={() => handleImageDelete(index)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
    <div
      className={`drag-file-area ${dragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="upload_icon">
        <i className="fa-solid fa-upload"></i>
      </div>
      <h3 className="dynamic-message">
        Drag & drop image files here
      </h3>
      <label htmlFor="file-upload" className="custom-input">
        Choose Image
      </label>
      <input type="file" id="file-upload" className="file-input" accept="image/*" multiple onChange={handleFileInputChange} />
      <div className="progress-bar"></div>
    </div>
    </>
  );
};

export default ImageUploader;

