//prdoct//
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "./ConstFile";
import { arrayBufferToBase64 } from "../utils/ImageBinaryConverter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function ProductDetail({ productId }) {
  // const { productId } = useParams();
  const [products, setProducts] = useState([]);

  const fetchProductDetails = async () => {
    let listings = await fetch(`${API_URL}productDetails/${productId}`, {
      method: "GET",
    });
    listings = await listings.json();
    if (listings) {
      setProducts(listings);
    }
  };
  useEffect(() => {
    fetchProductDetails();
  }, []);
  const getFormattedDate = (selecteDate) => {
    if (selecteDate) {
      const start = new Date(selecteDate); // Replace this with your actual date
      const day = String(start.getDate()).padStart(2, "0");
      const month = String(start.getMonth() + 1).padStart(2, "0");
      const year = String(start.getFullYear()).slice(-2); // Get the last two digits of the year
      const formattedStartDate = `${day}/${month}/${year}`;
      return formattedStartDate;
    }
    return selecteDate;
  };
  return (
    <>
      <div className="product_details">
        {products.map((product, index) => {
          let base64String = ""; // Define base64String here within the map function
          if (product.selectedImageUrl && product.selectedImageUrl.data) {
            base64String = arrayBufferToBase64(
              product.selectedImageUrl.data.data
            );
          }
          return (
            
            <div key={index} className="row">
              <h2 className="mb-4 detail_page_text text-break">
               {product.title}
                </h2>
              <div className="col-12 col-md-12">
              <Carousel  className="Carousel_ mb-carousel">
              {product.selectedImageUrl.map((productimg, index) => {
                
                let base64String = "";
                if (productimg && productimg.data) {
                  base64String = arrayBufferToBase64(
                    productimg.data.data
                  );
                }
                return(
                <div>
                    <img
                      src={
                        productimg.data
                          ? `data:image/png;base64,${base64String}`
                          : process.env.PUBLIC_URL + "/img/product-1.png"
                      }
                      alt="Indralokam"
                      className="product-image"
                    />
                    
                </div>

              )})}
               
            </Carousel>
                {/* <img
                  src={
                    product.selectedImageUrl && product.selectedImageUrl.data
                      ? `data:image/png;base64,${base64String}`
                      : process.env.PUBLIC_URL + "/img/product-1.png"
                  }
                  alt={
                    product.selectedImageUrl && product.selectedImageUrl.data
                      ? "Selected Image Alt Text"
                      : "Default Image Alt Text"
                  }
                  className="w-100"
                /> */}
              </div>
              <div className="col-12 col-md-12">
                {/* <h2 className="mb-4 detail_page_text text-break">
                  <strong>Title:</strong> {product.title}
                </h2> */}
                {product.locality && (
                <h5 className="mb-3 detail_page_text text-break">
                  <strong>Locality :</strong> {product.locality }
                </h5>
                )}
                <h5 className="mb-3 detail_page_text text-break">
                  <strong>Type:</strong> {product.selectedParentBoxVal}
                </h5>
                <h5 className="mb-3 detail_page_text text-break">
                  <strong>Description:</strong> {product.description}
                </h5>
                <h5 className="mb-3 detail_page_text">
                  <strong>Price:</strong> ₹ {product.price}
                </h5>
                <h5 className="mb-3 detail_page_text">
                  <strong>Contact Number:</strong>  {product.number}
                </h5>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProductDetail;
