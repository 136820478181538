import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "./ConstFile";

const DateRangePicker = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleDateChange = async (dates) => {
    const [start] = dates;
    const today = new Date(); // Get today's date
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1); // Calculate yesterday's date

    setStartDate(start);
    const calculatedEndDate = new Date(start);
    calculatedEndDate.setDate(calculatedEndDate.getDate() + 29);
    setEndDate(calculatedEndDate);
    const formStartDate = start;
    const formEndDate = calculatedEndDate;

    const lastIndexOfExcludeDates = props.excludeDates ? props.excludeDates.length - 1 : -1;
    let lastindex = lastIndexOfExcludeDates >= 0 ? props.excludeDates[lastIndexOfExcludeDates] : null;

    if (lastindex && formStartDate < lastindex) {
      // alert("You Can Not Select This Date");
      setStartDate(null);
      setEndDate(null);
      setShowMessage(true);
    } else if (formStartDate <= yesterday) {
      // Disallow today's date and show an error message
      // alert("You Can Not Select Today's Date");
      setStartDate(null);
      setEndDate(null);
      setShowMessage(true);
    } else {
      setShowMessage(false); // Clear the message if a valid date is selected
      props.onSelectDates({ formStartDate, formEndDate });
    }
  };

  const formatDate = (date) => {
    return date ? date.toLocaleDateString("en-US") : "";
  };

  const isPreviousDate = (date) => {
    const today = new Date();
    return date < today;
  };

  const dayClassNames = (date) => {
    const isExcluded = props.excludeDates && props.excludeDates.some(excludedDate => date.toDateString() === new Date(excludedDate).toDateString());
    const isPastDate = isPreviousDate(date);

    return isExcluded || isPastDate ? "previous-date" : "";
  };

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setShowMessage(false);
  }, [props.excludeDates]);
 
  const CustomDatePickerInput = ({ onClick }) => (
    <input
      type="text"
      value={formatDate(startDate)}
      onClick={onClick}
      readOnly
    />
  );

  return (
    <>
      <div className="d-flex date_picker mt-4">
        <div className="col-6">
          <label className="label">From:</label> <br />
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<CustomDatePickerInput />}
            // minDate={new Date()}
            // excludeDates={props.excludeDates}
            dayClassName={dayClassNames}
          />
        </div>
        <div className="col-6">
          <div>
            <label className="label">To:</label>
            <br />
            <input type="text" value={formatDate(endDate)} readOnly />
          </div>
        </div>
      </div>
      {showMessage && (
        <p className="slot_error">If you are not able to select a date, the slot is likely booked for that period. Please scroll to another date in the calendar.</p>
      )}
    </>
  );
};

export default DateRangePicker;