import React from "react";
import ImageLoader from "./Imageloader";

function About() {
  return (
    <>
    <div className=' container-fluid p-0'>
    <div className='hero_sec_pages '>
        <h1 className='hero_heading'>ABOUT US</h1>
    </div>
    <div className="container-fluid about-page">
      <div className="container p-sm-5 p-3">

        <div className="row align-items-center">
          <div className="col-12 col-md-6">
        <h1 className='page-title'>About Indralokam</h1>
            <p className='page-contant'>Namaste and hello, we are a small startup, bootstrapping with no big name to back us. We just have our idea and your support just like any other average young entrepreneur. </p>
            <p className='page-contant'>When searching for stuff on general websites we usually end up scrolling endless pages and never seem to find what we are looking for. With Indralokam we keep things simple and sexy. Just one page to find your stuff and no scrolling. The ads are there for a limited period (starting out with a month) and then they disappear. We value your time and effort however small. 
            
            </p>
            <p className='page-contant'>We intend to keep things hot, moving and make browsing for stuff fun again.Hope you enjoy the experience. </p>
            <p className='page-contant'>
              <b>Location:</b> F4 Sairam Apartments, Sector-1, MVP colony, <br /> Visakhapatnam, Andhra Pradesh, India 530017. <br /> <br /> Team:<br /> <span className='site_name'>Indralokam</span>
            </p>
          </div>
          <div className="col-12 col-md-6 about_image">
          <ImageLoader src="./img/big-logo.jpg" height="629" width="629" alt="Indralokam" />
          </div>
        </div>
      </div>
     </div>
     </div>
     </>
  );
}

export default About;
