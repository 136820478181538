import React, { useState, useEffect } from "react";
import Dropdowncontent from "./Dropdowncontent";
import { API_URL,updateCity } from "./ConstFile";
import Modal from "react-modal";
import { arrayBufferToBase64 } from "../utils/ImageBinaryConverter";
import ImageLoader from "./Imageloader";
import { State, City } from "country-state-city";
import Select from "react-select";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function Section1(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCity, setSelectedCity] = useState("Select A city");
  const [selectedType, setselectedType] = useState("All");
  const [selectedValueType, setselectedValueType] = useState("All");
  const [SearchResult, setSearchResult] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [stateSelected, setStateSelected] = useState(false);
  const [selectedCityInput, setSelectedCityInput] = useState(null);
  const [indianStates, setIndianStates] = useState([]);
  const [selectedState, setSelectedState] = useState("Select State");
  const [stateCities, setStateCities] = useState([]);
  const cityMapping = updateCity;
  

  useEffect(() => {
    const indiaStates = State.getStatesOfCountry("IN");
    setIndianStates(indiaStates);
    setSelectedState();
  }, []);

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
    setError(false);

    if (inputValue.length < 7) {
      // Reset the search results if the query is empty or has fewer than 3 characters
      setSearchResults([]);
      setFilteredResults([]);
    }
  };

  const SearchResultOpen = (result) => {
    setSearchResult(result);
    setModalImage(""); // Reset the image URL
    setIsModalOpen(true);

    if (result.selectedImageUrl && result.selectedImageUrl.data) {
      const base64String = arrayBufferToBase64(
        result.selectedImageUrl.data.data
      );
      setModalImage(`data:image/png;base64,${base64String}`);
    }
  };

  const SearchResultClose = () => {
    setSearchResult(null);
    setIsModalOpen(false);
  };
  const hendleStatechange = (item) => {
    setSelectedState(item);
    setSelectedCityInput("null");
    setselectedType("All");
    setselectedValueType("All");
    const cities = City.getCitiesOfState("IN", item.isoCode);
   
    // Check and update city names based on the mapping
    const updatedCities = cities.map((city) => ({
      ...city,
      name: cityMapping[city.name] || city.name,
    }));
    setStateCities(updatedCities);

    let searchFilterVal = searchResults.filter(
      (result) => result.selectedState === item.name
    );

    setFilteredResults(searchFilterVal);
  };

  function truncateDescription(description, maxWords) {
    const words = description.split(" ");
    if (words.length <= maxWords) {
      return description;
    }
    const truncatedDescription = words.slice(0, maxWords).join(" ");
    return `${truncatedDescription}...`;
  }

  const handleSearchClick = async (e) => {
    setSelectedState("");
    e.preventDefault();
    try {
      setLoading(true);
      const encodedQuery = encodeURIComponent(searchQuery);

      const response = await fetch(`${API_URL}search?s=${encodedQuery}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        const filteredData = data.filter((result) => /[a-zA-Z]/.test(result));

        if (filteredData.length === 0) {
          setError(true);
        } else {
          setSearchResults(filteredData);
          setFilteredResults(filteredData);
        }
      } else {
        console.error("Error fetching data:", response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCityFilter = (item) => {
    setSelectedCityInput(item);
    setSelectedCity(item.name);

    setselectedType("All");
    setselectedValueType("All");

    let searchFilterVal = searchResults.filter(
      (result) => result.selectedCity === item.name
    );
    setFilteredResults(searchFilterVal);
  };

  const handleOptionFilter = (selectedType) => {
    setselectedType(selectedType);
    let searchFilterVal;

    if (selectedType === "All") {
      // Show all data when "All" is selected
      searchFilterVal = searchResults;
    } else {
      searchFilterVal = searchResults.filter(
        (result) => result.selectedParentBoxVal === selectedType
      );
    }

    if (selectedCity !== "Select A city") {
      searchFilterVal = searchFilterVal.filter(
        (result) => result.selectedCity === selectedCity
      );
    }
    if (selectedState !== "Select State") {
      searchFilterVal = searchFilterVal.filter(
        (result) => result.selectedState === selectedState.name
      );
    }

    setFilteredResults(searchFilterVal);
  };

  const handlePropertyTypeFilter = (selectedValueType) => {
    setselectedValueType(selectedValueType);
    let searchFilterVal;

    if (selectedValueType === "All") {
      // Show all data when "All" is selected
      searchFilterVal = searchResults;
    } else {
      searchFilterVal = searchResults.filter(
        (result) => result.selectedChildBoxVal === selectedValueType
      );
    }
    if (selectedType !== "All") {
      searchFilterVal = searchFilterVal.filter(
        (result) => result.selectedParentBoxVal === selectedType
      );
    }
    if (selectedCity !== "Select A city") {
      searchFilterVal = searchFilterVal.filter(
        (result) => result.selectedCity === selectedCity
      );
    }
    if (selectedState !== "Select State") {
      searchFilterVal = searchFilterVal.filter(
        (result) => result.selectedState === selectedState.name
      );
    }

    setFilteredResults(searchFilterVal);
  };

  const getFormattedDate = (selectedDate) => {
    if (selectedDate) {
      const start = new Date(selectedDate);
      const day = String(start.getDate()).padStart(2, "0");
      const month = String(start.getMonth() + 1).padStart(2, "0");
      const year = String(start.getFullYear()).slice(-2);
      const formattedStartDate = `${day}/${month}/${year}`;

      return formattedStartDate;
    }
    return selectedDate;
  };

  return (
    <>
      <div className="container-fluid bg-color-section1 py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-1"></div>
            <div className="col-md-8 col-sm-12">
              <form
                action="#"
                className="d-flex flex-wrap mb-rsp mt-md-4 mb-4 lg:mt-0 mobile-css"
              >
                <input
                  className="form-control w-75 tx_inputtt"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  minLength="3"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button
                  className="btn tx_btnn tx_submit"
                  type="submit"
                  onClick={handleSearchClick}
                  disabled={searchQuery.trim().length < 3}
                >
                  Search
                </button>
              </form>
            </div>
            <div className="col-md-3 col-sm-12 section">
              <ImageLoader
                src="./img/big-logo.jpg"
                height="319"
                width="319"
                alt="Indralokam"
              />
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="error-message">
          No results found for :<span className="query">{searchQuery}</span>.
          Please try a different keyword.
        </div>
      )}

      {loading ? (
        <div class="text-center py-5">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      ) : (
        <>
          {searchResults.length > 0 && (
            <div className="search_pagediv container">
              <div className="filter_div d-md-flex">
                <div className="city_div py-2 py-sm-2 py-md-5 px-3 width_50">
                  <Select
                    options={indianStates}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    getOptionValue={(options) => {
                      return options["isoCode"];
                    }}
                    value={selectedState}
                    onChange={hendleStatechange}
                    placeholder="Select State"
                  />
                </div>
                <div className="city_div py-2 py-sm-2 py-md-5 px-3 width_50">
                  <Select
                    options={stateCities}
                    getOptionLabel={(options) => {
                      return options["name"];
                    }}
                    value={selectedCityInput}
                    onChange={handleCityFilter}
                    placeholder="Select City"
                  />
                </div>

                <div className="city_div py-2 py-sm-2 py-md-5 px-3 width_25">
                  <select
                    name="Select Type"
                    id="Select Type"
                    value={selectedType}
                    onChange={(e) => handleOptionFilter(e.target.value)}
                  >
                    <option value="All">All Options</option>
                    <option value="Furniture">Furniture</option>
                    <option value="Property">Property</option>
                  </select>
                </div>
                <div
                  className="city_div py-2 py-sm-2 py-md-5 px-3 Property-Type width_25"
                  style={{
                    display: selectedType === "Property" ? "block" : "none",
                  }}
                >
                  <select
                    name="Select Value"
                    id="Select Value"
                    value={selectedValueType}
                    onChange={(e) => handlePropertyTypeFilter(e.target.value)}
                  >
                    <option value="All">All Types</option>
                    <option value="Rental">Rental</option>
                    <option value="Sale">Sale</option>
                  </select>
                </div>
              </div>

              <div className="d-flex flex-wrap">
                {filteredResults.map((result) => {
                  let base64String = "";

                  if (
                    result.selectedImageUrl &&
                    result.selectedImageUrl[0] &&
                    result.selectedImageUrl[0].data
                  ) {
                    base64String = arrayBufferToBase64(
                      result.selectedImageUrl[0].data.data
                    );
                  }

                  return (
                    <div
                      onClick={() => SearchResultOpen(result)}
                      className="homepage-links"
                      key={result.id}
                    >
                      <div className="product-box dash-product pointer ">
                        <img
                          src={
                            base64String
                              ? `data:image/png;base64,${base64String}`
                              : process.env.PUBLIC_URL + "/img/product-1.png"
                          }
                          alt={
                            base64String
                              ? "Selected Image Alt Text"
                              : "Default Image Alt Text"
                          }
                          className="product-image"
                        />
                        <div className="search_detail_sec_text title_product">
                          {result.title.length > 15
                            ? result.title.substring(0, 25) + "..."
                            : result.title}
                        </div>
                        <div className="search_detail_sec_text product-description">
                          {truncateDescription(result.description, 7)}
                        </div>
                        <div className="search_detail_sec_text product-price">
                          {result.slotPrice}
                        </div>
                        <div className="search_detail_sec_text product-price tx_type">
                          {result.selectedParentBoxVal}
                        </div>
                        <div className="search_detail_sec_text product-price city">
                          {result.selectedCity}
                        </div>
                        <div
                          className="search_detail_sec_text product-price tx_sub_type"
                          style={{
                            display:
                              result.selectedChildBoxVal === "undefined"
                                ? "none"
                                : "block",
                          }}
                        >
                          {result.selectedChildBoxVal}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="search_detail_sec_text product-price">
                            {getFormattedDate(result.selectedStartDate)}
                          </div>
                          <div className="search_detail_sec_text product-price">
                            {getFormattedDate(result.selectedEndDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {searchResults.length === 0 && !error && (
            <div>
              <Dropdowncontent />
            </div>
          )}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={SearchResultClose}
            contentLabel="Furniture Details Modal"
          >
            {SearchResult && (
              <div>
                <h1 className="mb-4 detail_page_text text-break">
                  {SearchResult.title}
                </h1>
                <div className="close-modal" onClick={SearchResultClose}>
                  <i class="fa-solid fa-xmark "></i>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Carousel className="Carousel_ mb-carousel">
                      {SearchResult.selectedImageUrl.map(
                        (productimg, index) => {
                          let base64String = "";
                          if (productimg && productimg.data) {
                            base64String = arrayBufferToBase64(
                              productimg.data.data
                            );
                          }
                          return (
                            <div>
                              <img
                                src={
                                  productimg.data
                                    ? `data:image/png;base64,${base64String}`
                                    : process.env.PUBLIC_URL +
                                      "/img/product-1.png"
                                }
                                alt="Indralokam"
                                className="product-image"
                              />
                            </div>
                          );
                        }
                      )}
                    </Carousel>
                  </div>
                  <div className="px-sm-5 px-3 col-12">
                    {SearchResult.locality && (
                      <h5 className="mb-3 detail_page_text text-break">
                        <strong>Locality :</strong> {SearchResult.locality}
                      </h5>
                    )}
                    <h5 className="mb-3 detail_page_text text-break">
                      <strong>Type:</strong> {SearchResult.selectedParentBoxVal}
                    </h5>
                    <h5 className="mb-3 detail_page_text text-break">
                      <strong>Description:</strong> {SearchResult.description}
                    </h5>
                    <h5 className="mb-3 detail_page_text">
                      <strong>Price:</strong> ₹ {SearchResult.price}
                    </h5>
                    <h5 className="mb-3 detail_page_text">
                      <strong>Contact Number:</strong> {SearchResult.number}
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </>
      )}
    </>
  );
}
