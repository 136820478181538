import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes  } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Maindashbord from "./components/Maindashbord";
import About from "./components/About";
import Faq from "./components/Faq";
import PrivateComponent from "./components/privateComponent";
import Changepassword from "./components/Changepassword";
import Invite from "./components/Invite";
import React, { useEffect } from 'react';
import Section1 from "./components/Section1";
import Forgotpassword from "./components/Forgotpassword";
import Footer from "./components/Footer";
import Termsofuse from "./components/Termsofuse";
import Privacypolicy from "./components/Privacypolicy";
import Refund from "./components/Refund";
import ProductDetail from "./components/ProductDetail";
import Welcome from "./components/Welcome";
import Success from "./components/Success";

function App() {

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;

      script.onload = () => {
        resolve(true);
      }
      script.onerror = () => {
        resolve(false);
      }

      document.body.appendChild(script);
    });
  }

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js")
      .then((scriptLoaded) => {
        if (scriptLoaded) {
          // console.log('Razorpay script loaded successfully');
          // You might want to do something else here if the script loaded successfully
        } else {
          console.error('Failed to load Razorpay script');
          // Handle error if the script failed to load
        }
      });
  }, []);
 

  return (
    <>
     <Router>
        <div className="app">
            <Navbar />     
            <Routes >
              <Route element={<PrivateComponent />}>
                <Route path="/main-dashboard" element={<Maindashbord />} />
                <Route path="/change-password" element={<Changepassword />} />
              </Route>
            <Route path="/" element={<Section1 />} />
            <Route path="/home" element={<Section1 />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/about" element={<About />} />
            <Route path="/forgot-password" element={<Forgotpassword />} />
            <Route path="/invite" element={<Invite />} />
            <Route path="/termsofuse" element={<Termsofuse />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/refundandcancle" element={<Refund />} />
            <Route path="/product/:productId" element={<ProductDetail />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/success" element={<Success />} />

          </Routes >
          <Footer />      
          </div>
      
      </Router>

    </>
  );
}

export default App;
